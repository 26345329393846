.new-sidebar-menu {
  width: $sidebar-width;
  z-index: 10;
  background: #151515;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  padding-top: $header-height;
  box-shadow: $box-shadow;

  .navbar-brand-box {
    position: fixed;
    top: 0px;
    z-index: 99;
    display: block;

    .logo {
      display: flex;
      height: 72px;
      justify-content: center;
      align-items: center;
    }
  }

  .navbar-header-mobile {
    display: none;
  }

  .list-menu-sidebar {
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin-top: 20px;

    .list-menu {
      list-style-type: none;
      padding: 0px;
      margin: 0px;

      .list {
        position: relative;

        .list-link {
          padding: 12px 0px 12px 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          font-family: $font-family-sans-serif;
          cursor: pointer;
          color: #a6b0cf;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;

          .icon {
            display: block;
          }

          svg {
            width: 24px;
            height: 24px;

            &.list-chevron {
              width: 12px;
              height: 12px;
            }
          }

          &:hover {
            color: $primary;
          }

          &.active {
            color: $primary;
          }
        }

        .list-sub-menu {
          margin-left: 56px;
          list-style-type: none;
          padding: 0px;
          margin-bottom: 0px;

          .list-sub {
            .list-link {
              padding: 12px 0px;
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 8px;
              font-family: $font-family-sans-serif;
              cursor: pointer;
              color: #a6b0cf;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;

              svg {
                width: 24px;
                height: 24px;

                &.list-chevron {
                  width: 12px;
                  height: 12px;
                }
              }

              &:hover {
                color: $primary;
              }

              &.active {
                color: $primary;
              }
            }
          }
        }

        .onboarding-menu {
          width: 284px;
          position: absolute;
          top: -55px;
          right: -310px;
          bottom: 0;

          .onboarding-menu-card {
            display: flex;
            flex-direction: column;
            position: relative;

            .onboarding-card-title {
              background: #f0f0f0;
              padding: 8px 12px;
              border-radius: 4px 4px 0px 0px;
              border: 0.5px solid #bebebe;

              span {
                font-size: 16px;
                color: #000;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.5px;
              }
            }

            .onboarding-card-content {
              padding: 10px 12px;
              flex: 1;
              background-color: #ffffff;
              border-radius: 0px 0px 4px 4px;
              border-width: 0px 0.5px 0.5px 0.5px;
              border-color: #bebebe;
              border-style: solid;

              span {
                color: #000;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
              }

              .section-button {
                margin-top: 10px;
                gap: 10px;

                button {
                  display: flex;
                  height: 32px;
                  padding: 12px 16px;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  flex: 1;
                  border-radius: 4px;

                  &.btn-skip {
                    border: 1px solid #29ccb1;
                    background-color: #fff;
                  }

                  &.btn-next {
                    border: 1px solid #29ccb1;
                    background-color: #29ccb1;
                    color: #fff;
                  }
                }
              }
            }

            .triangle-icon {
              position: absolute;
              top: 50%;
              transform: translatey(-50%);
              left: -15px;
            }
          }
        }

        .onboarding-submenu {
          width: 284px;
          position: absolute;
          top: -64px;
          right: -300px;
          bottom: 0;

          .onboarding-submenu-card {
            display: flex;
            flex-direction: column;
            position: relative;

            .onboarding-submenu-card-title {
              background: #f0f0f0;
              padding: 8px 12px;
              border-radius: 4px 4px 0px 0px;
              border: 0.5px solid #bebebe;

              span {
                font-size: 16px;
                color: #000;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.5px;
              }
            }

            .onboarding-submenu-card-content {
              padding: 10px 12px;
              flex: 1;
              background-color: #ffffff;
              border-radius: 0px 0px 4px 4px;
              border-width: 0px 0.5px 0.5px 0.5px;
              border-color: #bebebe;
              border-style: solid;

              span {
                color: #000;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.5px;
              }

              .section-button {
                margin-top: 10px;
                gap: 10px;

                button {
                  display: flex;
                  height: 32px;
                  padding: 12px 16px;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  flex: 1;
                  border-radius: 4px;
                  border: 1px solid #29ccb1;
                  background-color: #29ccb1;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }

  .list-menu-mobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .new-sidebar-menu {
    z-index: 1003;
    top: 0px;
    display: none;
    padding-top: 0px;

    .navbar-brand-box {
      display: none;
    }

    &.open-side-bar {
      width: 100%;
      display: block;
    }

    .navbar-header-mobile {
      display: block;
    }

    .list-menu-sidebar {
      display: flex;
      flex-direction: column;
      padding: 0px;
      margin-top: 20px;

      .list-menu {
        .list {
          .list-link {
            .icon {
              display: none;
            }
          }
        }
      }
    }

    .list-menu-mobile {
      display: block;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 24px 0px 12px 24px;
      margin-top: 24px;
      gap: 24px;
      border-top: 1px solid #c2c7cf;
      font-family: "Poppins", sans-serif;
      cursor: pointer;

      .list {
        color: #a6b0cf;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }

      .mobile-header {
        display: block;
        padding: 16px;
        margin: -24px -24px 0px;
        border-bottom: 1px solid #c2c7cf;
        background-color: #fff;

        .content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 20px;

          .icon-close {
            width: 28px;
            height: 28px;
          }

          .title {
            font-size: 18px;
            font-weight: 600;
          }
        }
      }

      .modal-mobile-sidebar {
        position: fixed;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
        width: 100%;
        padding: 24px 24px 36px;
        z-index: 10000;
        background-color: #f7f7fa;
        box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
        -webkit-box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
        -moz-box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
        overflow-y: auto;
        overflow-x: hidden;

        .modal-accuracy-section {
          display: flex;
          flex-direction: column;
          position: relative;

          .icon-close {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 30px;
            height: 30px;
            cursor: pointer;
          }

          .title {
            font-size: 20px;
            font-weight: 600;
            margin-top: 20px;
          }

          .subtitle {
            font-size: 13px;
            font-weight: 400;
            margin-top: 4px;
            color: #616d84;
          }

          .modal-accuracy-section-content {
            margin-top: 12px;
            padding: 16px 16px 18px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 18px;

            .score {
              font-size: 40px;
              font-weight: 700;
            }

            .status {
              font-size: 14px;
            }

            .section-button {
              display: flex;
              justify-content: space-between;
              flex-direction: row;
              width: 100%;
            }
          }
        }

        .modal-operation-section {
          display: flex;
          flex-direction: column;
          position: relative;

          .icon-close {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 30px;
            height: 30px;
            cursor: pointer;
          }

          .title {
            font-size: 20px;
            font-weight: 600;
            margin-top: 20px;
          }

          .subtitle {
            font-size: 13px;
            font-weight: 400;
            margin-top: 4px;
            color: #616d84;
          }

          .modal-operation-section-content {
            gap: 36px;

            .content {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              align-items: center;
              gap: 12px;

              .title-content {
                text-transform: capitalize;
                font-size: 12px;
                font-weight: 700;
                width: 80px;
              }

              .loading-switch {
                width: 55px;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .dropdown-content {
                display: flex;
                flex-direction: row;
                flex: 1;
                flex-basis: 100%;
                gap: 20px;

                .dropdown-checkbox-costum-operation {
                  position: relative;
                  flex: 1;

                  .dropdown-checkbox {
                    border-radius: 4px;
                    border: 1px solid #a4abb8;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 8px 10px;
                    cursor: pointer;

                    .dropdown-toggle {
                      max-width: 118px;
                      font-family: $font-family-sans-serif;
                      font-size: 12px;
                      flex: 1;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 24px;
                      letter-spacing: 0.5px;
                      display: inline-block;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;

                      &.active {
                        color: #131313;
                      }

                      &.inactive {
                        color: #7b8598;
                      }
                    }

                    .chevron-down {
                      width: 24px;
                      height: 24px;
                    }
                  }

                  .dropdown-checkbox-list {
                    display: flex;
                    flex-direction: column;
                    gap: 3px;
                    padding: 8px 16px;
                    position: absolute;
                    background: #fff;
                    border: 1px solid #a4abb8;
                    top: 47px;
                    left: 0px;
                    right: 0px;
                    border-radius: 4px;
                    z-index: 10;

                    .checkbox-label {
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      margin: 0px;
                      color: #293448;
                      font-family: $font-family-sans-serif;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 24px;
                      gap: 8px;

                      .checkbox-input {
                        border: 1px solid #a4abb8;
                        width: 16px;
                        height: 16px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
