.page-agent-profile {
  font-family: "Poppins", sans-serif;

  .page-agent-profile-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    cursor: pointer;

    img {
      width: 35px;
      height: 35px;
    }

    .page-agent-profile-menu-title {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .title::first-letter {
        text-transform: capitalize;
      }

      .date {
        color: #949494;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
