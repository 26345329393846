.section-rating {
  font-family: $font-family-sans-serif;
  background-color: white;

  .title-rating-page {
    font-style: bold;
    font-weight: 600;
    line-height: normal;
    font-size: 16;
  }

  .rating-regular {
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 14;
  }

  .section-package {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 24px;
    border-radius: 4px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.02);
    height: 100%;

    .section-package-now {
      flex: 1;

      .title-package {
        color: #79747e;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-size: 14px;
      }

      .package {
        display: flex;
        flex-direction: column;
        margin-top: 12px;

        .name {
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          color: $black;
        }
      }

      .section-package-button {
        display: flex;
        flex-direction: row;
        gap: 4px;
        margin-top: 12px;

        .upgrade {
          color: $primary;
          font-weight: 500;
          font-style: normal;
          font-size: 12px;
          cursor: pointer;
        }

        .info {
          color: #ccc;
          font-style: normal;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }

    .section-quota {
      flex: 1;

      .title-package {
        color: #79747e;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-size: 14px;
      }

      .section-quota-remaining {
        display: flex;
        flex-direction: row;
        margin-top: 12px;
        gap: 10px;
        justify-content: flex-start;
        flex-wrap: wrap;

        .quota {
          h6 {
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            color: $black;
          }
        }
      }
    }
  }

  .checkbox-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px;
    color: #293448;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    gap: 8px;

    .checkbox-input {
      border: 1px solid #a4abb8;
      width: 16px;
      height: 16px;
    }
  }

  .section-tabel {
    margin-top: 30px;
    background-color: #ffffff;
    padding: 24px;
    border-radius: 4px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.02);

    table,
    td,
    th {
      border: 1px solid #b9b9b9;
    }

    table {
      border-collapse: collapse;
      border: 1px solid #b9b9b9;

      thead {
        background-color: #f0f0f0;

        tr {
          th {
            font-weight: 600;
            color: $black;
          }
        }
      }

      tbody {
        tr {
          td {
            background-color: #ffffff;
          }
        }
      }
    }

    .tabel-no-data {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 20px;
      width: 100%;
      border-width: 0px 1px 1px 1px;
      border-color: #ccc;
      border-style: solid;
      margin-top: -20px;
    }
  }
}

.modal-content {
  .rating-modal-section {
    font-style: bold;
    font-weight: 600;
    line-height: normal;
    font-size: 16px;
    border: 1px solid #ccc;
    border-top: unset;
    border-left: unset;
    border-right: unset;
    margin-bottom: 16px;
  }
  .checkbox-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px;
    color: #293448;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    gap: 8px;

    .checkbox-input {
      border: 1px solid #a4abb8;
      width: 16px;
      height: 16px;
    }
  }

  .row > * {
    padding-left: unset;
  }
}
