.switch {
  position: relative;
  display: inline-flex;
  height: 30px;
  width: 55px;
  align-items: center;
  border-radius: 9999px;
  border: none;

  .icon {
    width: 22px !important;
    height: 22px !important;
    position: absolute;

    &.check-icon {
      left: 5px;
      top: 4px;
      bottom: 0px;
    }

    &.active {
      color: white;
    }

    &.inactive {
      color: #ebebec;
    }
  }

  &.bg-active {
    background-color: #29ccb1;
  }

  &.bg-inactive {
    background-color: #cccccc;
  }

  .switch-toggle {
    display: inline-block;
    height: 24px;
    width: 24px;
    pointer-events: none;
    display: inline-block;
    border-radius: 50%;
    box-shadow: 0px 2px 4px rgba(0, 35, 11, 0.2);
    border: 0;
    transition: all 200ms ease-in-out;

    &.active {
      transform: translateX(22px);
      background-color: #fff;
    }

    &.inactive {
      transform: translateX(-3px);
      background-color: #ebebec;
    }
  }
}
