.dropdown-costume-selected {
  .title {
    color: #181f2a;
    font-family: $font-family-sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  .title-light {
    color: #181f2a;
    font-family: $font-family-sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .dropdown-selected {
    position: relative;
    margin-top: 8px;

    .dropdown-selected-button {
      position: relative;
      width: 100%;
      cursor: default;
      border-radius: 0.375rem;
      background-color: #fff;
      padding: 8px 16px;
      text-align: left;
      outline: 2px solid transparent;
      outline-offset: 2px;
      border: 1px solid #c2c7cf;

      &.error-selected {
        border: 1px solid $danger;
        outline: none;
      }

      &:focus {
        border: 1px solid $primary;
        outline: none;
      }

      &:focus-visible {
        border: 1px solid $primary;
        outline: none;
      }

      .dropdown-selected-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.default {
          color: #74788d;
        }
      }

      .dropdown-selected-icon {
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding-right: 0.5rem;

        .icon {
          height: 1.25rem;
          width: 1.25rem;
          color: #cbd5e0;
        }
      }
    }

    .dropdown-selected-list {
      position: absolute;
      z-index: 10;
      margin: 0.25rem 0px 0px;
      max-height: 15rem;
      width: 100%;
      overflow: auto;
      border-radius: 0.375rem;
      background-color: #fff;
      padding: 0.25rem 0px 0px;
      font-size: 1rem;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.1);
      outline: none;
      list-style: none;

      &:focus {
        outline: none;
      }

      @media (min-width: 640px) {
        font-size: 0.875rem;
      }

      .list {
        position: relative;
        cursor: default;
        user-select: none;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 2.5rem;
        padding-right: 1rem;

        .list-icon {
          position: absolute;
          bottom: 0;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          padding-left: 0.75rem;
          color: $primary;

          .icon {
            height: 1.25rem;
            width: 1.25rem;
          }
        }
      }
    }
  }
}

.transition {
  transition: ease-in 100ms;
}

.ease-in {
  transition-timing-function: ease-in;
}

.duration-100 {
  transition-duration: 100ms;
}

.opacity-100 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}
