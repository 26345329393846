//
// Widgets.scss
//

.mini-stats-wid {
  .mini-stat-icon {
    overflow: hidden;
    position: relative;
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 8px;
      height: 54px;
      background-color: rgba($white, 0.1);
      left: 16px;
      transform: rotate(32deg);
      top: -5px;
      transition: all 0.4s;
    }

    &::after {
      left: -12px;
      width: 12px;
      transition: all 0.2s;
    }
  }

  &:hover {
    .mini-stat-icon {
      &::after {
        left: 60px;
      }
    }
  }
}

.accuracy-section {
  position: relative;
  font-family: $font-family-sans-serif;

  .btn-accuracy {
    padding: 9px 16px;
    border: 1px solid $primary;
    border-radius: 4px;
    z-index: 10;
    position: relative;
    cursor: pointer;

    span {
      color: $primary;
    }
  }

  .modal-accuracy {
    position: absolute;
    top: 60px;
    right: 0px;
    width: 400px;
    border-radius: 16px;
    padding: 24px 24px 36px;
    z-index: 10000;
    background-color: #fff;
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
    -webkit-box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
    -moz-box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;

    .modal-accuracy-section {
      display: flex;
      flex-direction: column;
      position: relative;

      .icon-close {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 30px;
        height: 30px;
        cursor: pointer;
      }

      .title {
        font-size: 20px;
        font-weight: 600;
        margin-top: 20px;
      }

      .subtitle {
        font-size: 13px;
        font-weight: 400;
        margin-top: 4px;
        color: #616d84;
      }

      .modal-accuracy-section-content {
        margin-top: 12px;
        padding: 16px 16px 18px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 18px;

        .score {
          font-size: 40px;
          font-weight: 700;
        }

        .status {
          font-size: 14px;
        }

        .section-button {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          width: 100%;
        }
      }
    }
  }

  .modal-operation {
    position: absolute;
    top: 60px;
    right: 0px;
    width: 550px;
    height: auto;
    border-radius: 16px;
    padding: 24px 24px 36px;
    z-index: 10000;
    background-color: #fff;
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
    -webkit-box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
    -moz-box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;

    .modal-operation-section {
      display: flex;
      flex-direction: column;
      position: relative;

      .icon-close {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 30px;
        height: 30px;
        cursor: pointer;
      }

      .title {
        font-size: 20px;
        font-weight: 600;
        margin-top: 20px;
      }

      .subtitle {
        font-size: 13px;
        font-weight: 400;
        margin-top: 4px;
        color: #616d84;
      }

      .modal-operation-section-content {
        gap: 12px;

        .content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 12px;

          .title-content {
            text-transform: capitalize;
            font-size: 12px;
            font-weight: 700;
            width: 80px;
          }

          .loading-switch {
            width: 55px;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .dropdown-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 12px;
            flex: 1;

            .dropdown-checkbox-costum-operation {
              position: relative;
              flex: 1;

              .dropdown-checkbox {
                border-radius: 4px;
                border: 1px solid #a4abb8;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 8px 10px;
                cursor: pointer;

                .dropdown-toggle {
                  max-width: 118px;
                  font-family: $font-family-sans-serif;
                  font-size: 12px;
                  flex: 1;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                  letter-spacing: 0.5px;
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  &.active {
                    color: #131313;
                  }

                  &.inactive {
                    color: #7b8598;
                  }
                }

                .chevron-down {
                  width: 24px;
                  height: 24px;
                }
              }

              .dropdown-checkbox-list {
                display: flex;
                flex-direction: column;
                gap: 3px;
                padding: 8px 16px;
                position: absolute;
                background: #fff;
                border: 1px solid #a4abb8;
                top: 47px;
                left: 0px;
                right: 0px;
                border-radius: 4px;
                z-index: 10;

                .checkbox-label {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin: 0px;
                  color: #293448;
                  font-family: $font-family-sans-serif;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                  gap: 8px;

                  .checkbox-input {
                    border: 1px solid #a4abb8;
                    width: 16px;
                    height: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .accuracy-section {
    flex-basis: 100%;
    text-align: center;
  }
}
