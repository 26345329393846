//
// ecommerce.scss
//

// product
.question-list-add-knowledge {
  display: flex;
  width: 100%;
  justify-content: flex-end;

  .btn-add-service-knowledge {
    margin: 0px 0px 16px 0px;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.search-box {
  .form-control {
    border-radius: 30px;
    padding-left: 40px;
  }

  .search-icon {
    font-size: 16px;
    position: absolute;
    left: 13px;
    top: 0;
    line-height: 38px;
  }
}

.product-list {
  li {
    a {
      display: block;
      padding: 4px 0px;
      color: $body-color;
    }
  }
}

.product-view-nav {
  &.nav-pills {
    .nav-item {
      margin-left: 4px;
    }

    .nav-link {
      width: 36px;
      height: 36px;
      font-size: 16px;
      padding: 0;
      line-height: 36px;
      text-align: center;
      border-radius: 50%;
    }
  }
}

.product-ribbon {
  position: absolute;
  right: 0px;
  top: 0px;
}

// Product Details

.product-detai-imgs {
  .nav {
    .nav-link {
      margin: 7px 0px;

      &.active {
        background-color: $gray-300;
      }
    }
  }
}

.product-color {
  a {
    display: inline-block;
    text-align: center;
    color: $body-color;

    .product-color-item {
      margin: 7px;
    }

    &.active,
    &:hover {
      color: $primary;

      .product-color-item {
        border-color: $primary !important;
      }
    }
  }
}

// ecommerce cart

.visa-card {
  .visa-logo {
    line-height: 0.5;
  }

  .visa-pattern {
    position: absolute;
    font-size: 385px;
    color: rgba($white, 0.05);
    line-height: 0.4;
    right: 0px;
    bottom: 0px;
  }
}

// checkout

.checkout-tabs {
  .nav-pills {
    .nav-link {
      margin-bottom: 24px;
      text-align: center;
      background-color: $card-bg;
      box-shadow: $box-shadow;

      &.active {
        background-color: $primary;
      }

      .check-nav-icon {
        font-size: 36px;
      }
    }
  }
}

.billing-user {
  .plan-name {
    color: $primary;
  }
}

.page-ecommerce {
  margin-bottom: 60px;
  font-family: $font-family-sans-serif;

  .title-page {
    color: #181f2a;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 16px;
  }

  .subtitle-page {
    color: #293448;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .page-ecommerce-content {
    margin-top: 26px;

    .register-store-summary {
      margin-top: 60px;

      .register-store-summary-content {
        width: 100%;
        margin: 0px auto;
        background-color: #fff;
        padding: 80px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .register-store-summary-main-content {
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;

          .title {
            color: #181f2a;
            font-family: $font-family-sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          .subtitle {
            color: #7b8598;
            text-align: center;
            font-family: $font-family-sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .btn-empty {
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }

    .integrated-store-summary {
      margin-top: 16px;

      .store-summary-information {
        .title {
          color: #181f2a;
          font-family: $font-family-sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .subtitle {
          color: #293448;
          font-family: $font-family-sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .store-summary-information-content {
          display: grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));
          justify-content: space-between;
          margin-top: 16px;
          gap: 10px;
          margin-bottom: 24px;

          .card-store-information {
            display: flex;
            flex: 1 0 calc(33.33% - 8px);
            padding: 16px 24px;
            flex-direction: row;
            gap: 8px;
            border-radius: 4px;
            align-items: center;
            background: #fff;
            box-shadow: 0px 6px 6px 0px rgba(123, 138, 145, 0.1);
            justify-content: space-between;

            .card-store-information-content {
              display: flex;
              flex-direction: column;

              &.product-sync {
                flex-direction: row;
                display: flex;
                justify-content: space-between;
                width: 100%;

                .product-content {
                  display: flex;
                  flex-direction: column;
                }

                .button-sync {
                  .btn {
                    width: 100%;
                    font-size: 12px;
                  }
                }
              }

              .title-card-store-information {
                display: flex;
                flex-direction: row;
                align-items: center;

                span {
                  color: #181f2a;
                  font-family: $font-family-sans-serif;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                svg {
                  width: 20px;
                  height: 20px;
                  margin-left: 4px;
                  cursor: pointer;
                }
              }

              .value-card-store-information {
                color: #181f2a;
                font-family: $font-family-sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
            }
          }

          button {
            max-width: 135px;
            padding: 12px 16px;

            svg {
              margin-right: 6px;
            }
          }
        }

        .store-summary-product-content {
          margin-top: 16px;
          padding: 32px 16px;
          background: #fff;
          border-radius: 4px;

          .header-product-store-section {
            .header-product-store {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              margin-bottom: 16px;

              .input-search-product-store {
                position: relative;
                min-width: 320px;

                .input-icon {
                  pointer-events: none;
                  position: absolute;
                  inset: 0;
                  left: 0;
                  display: flex;
                  align-items: center;
                  padding-left: 16px;

                  .icon {
                    width: 24px;
                    height: 24px;
                    color: "#7B8598";
                  }
                }

                .input-search {
                  display: block;
                  width: 100%;
                  border-radius: 4px;
                  height: 100%;
                  padding: 8px 16px 8px 50px;
                  color: #374151;
                  border: 1px solid #a4abb8;

                  &:focus {
                    outline: none;
                    border-width: 1px;
                    border-color: $primary;
                  }

                  &::placeholder {
                    color: #a5b4cc;
                  }
                }
              }

              .dropdown-checkbox-costum {
                display: block;
              }

              .dropdown-checkbox-mobile {
                display: none;
              }
            }

            .header-product-store-filter {
              margin-bottom: 16px;
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 12px;

              .reset-filter {
                color: #29ccb1;
                font-family: $font-family-sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                cursor: pointer;
              }

              .spacer {
                font-size: 20px;
                color: #a4abb8;
                margin: 0px;
                display: block;
              }

              .filter-active {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                .btn-filter {
                  display: flex;
                  padding: 4px 8px;
                  align-items: center;
                  gap: 4px;
                  border-radius: 8px;
                  border: 1px solid #a4abb8;

                  .icon {
                    width: 18px;
                    height: 18px;
                  }
                }
              }
            }
          }

          .section-edit-product-recommend {
            display: flex;
            width: 100%;
            justify-content: flex-end;

            .btn-edit-product-recommend {
              margin: 0px 16px 16px 0px;
              cursor: pointer;

              svg {
                width: 20px;
                height: 20px;
              }
            }
          }

          .store-summary-product {
            display: grid;
            grid-auto-flow: row;
            grid-template-columns: repeat(5, minmax(0, 1fr));
            gap: 16px;

            .card-store-product {
              flex-basis: calc(20% - 16px);
              display: flex;
              padding: 16px;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              border-radius: 4px;
              background: #fff;
              box-shadow: 0px 6px 6px 0px rgba(123, 138, 145, 0.1);
              position: relative;
              cursor: pointer;

              .card-store-img {
                width: 100%;
                height: 230px;
                object-fit: contain;
                display: block;

                .empty-store-img {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100%;
                }
              }

              .badge-product-summary {
                display: flex;
                padding: 4px 8px;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                margin-top: 10px;
                background: #eaeffb;
                color: #12265b;
              }

              .title-product {
                color: #181f2a;
                font-family: $font-family-sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }

              .price-product {
                color: #293448;
                font-family: $font-family-sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }

              .stock-product {
                color: #7b8598;
                font-family: $font-family-sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            .card-service-knowledge {
              flex-basis: calc(20% - 16px);
              display: flex;
              padding: 16px;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              border-radius: 4px;
              background: #fff;
              box-shadow: 0px 6px 6px 0px rgba(123, 138, 145, 0.1);
              position: relative;
              cursor: pointer;

              .card-content {
                display: flex;
                flex-direction: column;

                .title-service-knowledge {
                  color: #181f2a;
                  font-family: $font-family-sans-serif;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                }

                .category-service-knowledge {
                  color: #181f2a;
                  font-family: $font-family-sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                .topic-service-knowledge {
                  color: #7b8598;
                  font-family: $font-family-sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  margin-top: 8px;
                }
              }
            }

            .card-empty-recommend {
              flex-basis: calc(20% - 16px);
              display: flex;
              padding: 16px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              position: relative;
              cursor: pointer;
              background: #fff;
              background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%2329CCB1FF' stroke-width='4' stroke-dasharray='16' stroke-dashoffset='32' stroke-linecap='square'/%3e%3c/svg%3e");
              border-radius: 4px;

              .card-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 4px;

                svg {
                  width: 34px;
                  height: 34px;
                  color: $primary;
                }

                span {
                  color: #616d84;
                  text-align: center;
                  font-family: $font-family-sans-serif;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  width: 150px;
                }
              }
            }

            .card-skeleton-knowledge-service {
              width: 100%;
              flex-basis: calc(20% - 16px);
              display: flex;
              padding: 16px;
              flex-direction: column;
              justify-content: space-between;
              border-radius: 4px;
              background: #fff;
              box-shadow: 0px 6px 6px 0px rgba(123, 138, 145, 0.1);
              position: relative;
            }
          }

          .store-summary-btn {
            display: flex;
            justify-content: flex-end;
            margin-top: 16px;

            .btn-summary-list-product {
              display: flex;
              flex-direction: row;
              gap: 6px;
              align-items: center;
              color: #29ccb1;
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;

              svg {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
      }
    }
  }
}

.page-content-knowledge {
  padding: calc(20px + 24px) calc(0px / 2) 60px calc(0px / 2);
  height: 100%;

  .service-knowledge-product-store {
    margin-top: 26px;
    margin-bottom: 30px;
    display: flex;
    padding: 32px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    background: linear-gradient(91deg, #29ccb1 -0.04%, #11564a 100.96%);

    .input-title {
      color: #fff;
      font-family: $font-family-sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      text-align: center;
    }

    .input-search-service-knowledge {
      position: relative;
      min-width: 680px;

      .input-icon {
        pointer-events: none;
        position: absolute;
        inset: 0;
        left: 0;
        display: flex;
        align-items: center;
        padding-left: 16px;

        .icon {
          width: 24px;
          height: 24px;
          color: "#7B8598";
        }
      }

      .input-search {
        display: block;
        width: 100%;
        border-radius: 4px;
        height: 100%;
        padding: 16px 16px 16px 50px;
        color: #374151;
        border: 1px solid #a4abb8;

        &:focus {
          outline: none;
          border-width: 1px;
          border-color: $primary;
        }

        &::placeholder {
          color: #a5b4cc;
        }
      }
    }
  }

  .service-knowledge-list-product-store {
    padding: 0px calc(24px / 2) 160px calc(24px / 2);

    .service-knowledge-product-add {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      .btn-add-service-knowledge {
        margin: 0px 0px 16px 0px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .service-knowledge-list {
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: repeat(5, minmax(0, 1fr));
      gap: 16px;

      .card-service-knowledge {
        flex-basis: calc(20% - 16px);
        display: flex;
        padding: 16px;
        flex-direction: row;
        justify-content: space-between;
        gap: 8px;
        border-radius: 4px;
        background: #fff;
        box-shadow: 0px 6px 6px 0px rgba(123, 138, 145, 0.1);
        position: relative;

        .card-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title-service-knowledge {
            color: #181f2a;
            font-family: $font-family-sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            max-width: 165px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .category-service-knowledge {
            color: #181f2a;
            font-family: $font-family-sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .topic-service-knowledge {
            margin-top: 8px;
            color: #7b8598;
            font-family: $font-family-sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .card-action {
          cursor: pointer;
          position: relative;

          .card-action-btn {
            max-width: 24px;
            padding: 1px 3px;
            margin-top: -8px;
            justify-content: center;
            display: flex;
            align-items: center;

            .icon {
              height: 20px;
              width: 20px;
            }
          }

          .card-action-modal {
            position: absolute;
            width: 150px;
            top: 10px;
            right: 0px;
            background: #fff;
            z-index: 10;
            display: flex;
            flex-direction: column;
            border-radius: 4px;
            box-shadow: 0px 6px 6px 0px rgba(123, 138, 145, 0.25);

            .btn-icon-action {
              display: flex;
              flex-direction: row;
              gap: 4px;
              padding: 8px;

              &.btn-edit {
                color: $primary;
                font-family: $font-family-sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                /* 21px */
              }

              &.btn-delete {
                color: #da2626;
                font-family: $font-family-sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                /* 21px */
              }

              svg {
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }

      .card-skeleton {
        width: 100%;
        flex-basis: calc(20% - 16px);
        display: flex;
        padding: 16px;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 4px;
        background: #fff;
        box-shadow: 0px 6px 6px 0px rgba(123, 138, 145, 0.1);
        position: relative;
      }
    }

    .service-knowledge-empty {
      margin-top: 60px;

      .service-knowledge-empty-content {
        width: 100%;
        margin: 0px auto;
        padding: 80px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .service-knowledge-empty-main-content {
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;

          .title {
            color: #181f2a;
            font-family: $font-family-sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          .subtitle {
            color: #7b8598;
            text-align: center;
            font-family: $font-family-sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .btn-empty {
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}

.page-ecommerce-details {
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  width: 1024px;
  max-width: 1024px;
  margin: 0px auto 60px;

  .btn-add-service-knowledge-mobile {
    display: none;
    margin-bottom: 30px;

    svg {
      width: 20px;
    }
  }

  .header-section {
    padding-bottom: 16px;
    border-bottom: 1px solid #c2c7cf;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .page-header-content {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;

      .title {
        color: #181f2a;
        font-family: $font-family-sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        text-transform: capitalize;
      }

      .section-edit-title {
        display: flex;
        flex-direction: row;
        align-items: center;

        .input-edit-title {
          padding: 6px 8px;
          border-radius: 4px;
          border: 1px solid #c2c7cf;
          color: #181f2a;
          font-family: $font-family-sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
        }

        .btn-edit {
          cursor: pointer;
          margin-left: 8px;
          padding: 1px;

          svg {
            width: 36px;
            height: 36px;
            color: $primary;

            &.disabled {
              color: #e0e7ec !important;
            }
          }
        }
      }
    }

    .page-header-action {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .category {
        border-radius: 8px;
        background: #ecf2fd;
        color: #000;
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        text-transform: capitalize;
        color: #12265b;
        font-family: $font-family-sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }

      .btn-add-service-knowledge {
        display: block;
        svg {
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
      }

      .card-action {
        cursor: pointer;
        position: relative;

        .card-action-btn {
          max-width: 24px;
          padding: 1px 3px;
          justify-content: center;
          display: flex;
          align-items: center;

          .icon {
            height: 20px;
            width: 20px;
          }
        }

        .card-action-modal {
          position: absolute;
          width: 170px;
          top: 35px;
          right: 0px;
          background: #fff;
          z-index: 10;
          display: flex;
          flex-direction: column;
          border-radius: 4px;
          box-shadow: 0px 6px 6px 0px rgba(123, 138, 145, 0.25);

          .btn-icon-action {
            display: flex;
            flex-direction: row;
            gap: 6px;
            padding: 12px 14px;

            &.btn-edit {
              color: $primary;
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
              /* 21px */
            }

            &.btn-delete {
              color: #da2626;
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
              /* 21px */
            }

            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }

    .subtitle {
      color: #293448;
      font-family: $font-family-sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-top: 12px;
    }
  }

  .header-section-costume {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .page-header-content {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;

      .title {
        color: #181f2a;
        font-family: $font-family-sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        text-transform: capitalize;
      }

      .section-edit-title {
        display: flex;
        flex-direction: row;
        align-items: center;

        .input-edit-title {
          padding: 6px 8px;
          border-radius: 4px;
          border: 1px solid #c2c7cf;
          color: #181f2a;
          font-family: $font-family-sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
        }

        .btn-edit {
          cursor: pointer;
          margin-left: 8px;
          padding: 1px;

          svg {
            width: 36px;
            height: 36px;
            color: $primary;

            &.disabled {
              color: #e0e7ec !important;
            }
          }
        }
      }
    }

    .page-header-action {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .category {
        border-radius: 8px;
        background: #ecf2fd;
        color: #000;
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        text-transform: capitalize;
        color: #12265b;
        font-family: $font-family-sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }

      .btn-add-service-knowledge {
        svg {
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
      }

      .card-action {
        cursor: pointer;
        position: relative;

        .card-action-btn {
          max-width: 24px;
          padding: 1px 3px;
          justify-content: center;
          display: flex;
          align-items: center;

          .icon {
            height: 20px;
            width: 20px;
          }
        }

        .card-action-modal {
          position: absolute;
          width: 170px;
          top: 35px;
          right: 0px;
          background: #fff;
          z-index: 10;
          display: flex;
          flex-direction: column;
          border-radius: 4px;
          box-shadow: 0px 6px 6px 0px rgba(123, 138, 145, 0.25);

          .btn-icon-action {
            display: flex;
            flex-direction: row;
            gap: 6px;
            padding: 12px 14px;

            &.btn-edit {
              color: $primary;
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
              /* 21px */
            }

            &.btn-delete {
              color: #da2626;
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
              /* 21px */
            }

            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }

    .page-header-action-mobile {
      display: none;
    }

    .subtitle {
      color: #293448;
      font-family: $font-family-sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-top: 12px;
    }
  }

  .page-ecommerce-details-content {
    margin-top: 16px;

    .title {
      color: #181f2a;
      font-family: $font-family-sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
    }

    .section-details-content {
      margin-top: 16px;

      .info-details-product-content {
        padding: 32px 0px;

        .details-product-img {
          width: 100%;
          height: 300px;
          object-fit: contain;
          display: block;
        }

        .details-product-info {
          display: flex;
          flex-direction: column;

          .badge-product-info {
            display: inline-block;
            max-width: max-content;
            padding: 4px 8px;
            border-radius: 8px;
            margin: 10px 0px 16px;
            background: #eaeffb;
            color: #12265b;
            font-size: 14px;
          }

          .price-product-info {
            color: #293448;
            font-family: $font-family-sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          .stock-product-info {
            color: #7b8598;
            font-family: $font-family-sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .desc-product-info {
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            gap: 12px;

            .info {
              display: flex;
              flex-direction: column;
              gap: 1px;

              .title {
                color: #293448;
                font-family: $font-family-sans-serif;
                font-size: 16px !important;
                font-weight: 600;
              }

              .description {
                color: #293448;
                font-family: $font-family-sans-serif;
                font-size: 14px !important;
                font-weight: 400;
              }
            }
          }
        }
      }
    }

    .section-list-product-recommend-add {
      margin-bottom: 24px;

      .recommend-product-text {
        color: #293448;
        font-family: $font-family-sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        .recommend-product-text-bold {
          color: #293448;
          font-family: $font-family-sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
        }
      }

      .recommend-product-list {
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: space-between;
        margin: 16px 0px;

        .card-recommend-small {
          flex-basis: calc(20% - 16px);
          display: flex;
          padding: 16px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          border-radius: 4px;
          background: #fff;
          box-shadow: 0px 6px 6px 0px rgba(123, 138, 145, 0.1);
          position: relative;
          cursor: pointer;
          max-height: 165px;
          height: 165px;

          .active-edit {
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            box-shadow: 0px 6px 6px 0px rgba(123, 138, 145, 0.1);
          }

          .card-recommend-img {
            width: 100%;
            height: 90px;
            object-fit: contain;
            display: block;
          }

          .title-recommend-product {
            color: #293448;
            margin-top: 16px;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            font-family: $font-family-sans-serif;
            line-height: normal;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        .card-empty-recommend-small {
          flex-basis: calc(20% - 16px);
          display: flex;
          padding: 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          position: relative;
          cursor: pointer;
          background: #fff;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23C2C7CFFF' stroke-width='4' stroke-dasharray='16' stroke-dashoffset='32' stroke-linecap='square'/%3e%3c/svg%3e");
          border-radius: 4px;
          max-height: 165px;
          height: 165px;

          .card-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;

            svg {
              width: 34px;
              height: 34px;
              color: #616d84;
            }

            span {
              color: #616d84;
              text-align: center;
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              width: 150px;
            }
          }
        }
      }
    }

    .section-search-product-recommend {
      .input-search-product-recommend {
        position: relative;
        max-width: 360px;
        width: 100%;

        .input-icon {
          pointer-events: none;
          position: absolute;
          inset: 0;
          left: 0;
          display: flex;
          align-items: center;
          padding-left: 16px;

          .icon {
            width: 24px;
            height: 24px;
            color: "#7B8598";
          }
        }

        .input-search {
          display: block;
          width: 100%;
          border-radius: 4px;
          height: 100%;
          padding: 16px 16px 16px 50px;
          color: #374151;
          border: 1px solid #a4abb8;

          &:focus {
            outline: none;
            border-width: 1px;
            border-color: $primary;
          }

          &::placeholder {
            color: #a5b4cc;
          }
        }
      }

      .list-product {
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: space-between;
        margin: 16px 0px;

        .card-product-checkbox {
          display: flex;
          flex-direction: row;
          gap: 12px;
          align-items: center;
          cursor: pointer;

          .checkbox {
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            border: 1px solid #c2c7cf;
            border-radius: 4px;

            &.active {
              background: $primary !important;
              border: 1px solid $primary;
            }

            svg {
              width: 16px;
              height: 16px;
              color: #fff;
            }

            .checkbox-number {
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              color: #fff;
            }
          }

          img {
            width: 64px;
            height: 64px;
            object-fit: contain;
            display: block;
          }

          span {
            color: #293448;
            font-family: $font-family-sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }
    }
  }

  .page-add-service-knowledge {
    margin-top: 16px;

    .section-add-selected {
      display: flex;
      justify-content: space-between;
      gap: 16px;

      .section-select {
        flex: 1;
      }

      .section-add {
        display: flex;
        align-items: flex-end;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .section-question {
      margin-top: 16px;
      border-bottom: 1px solid #c2c7cf;
      padding-bottom: 16px;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .title {
        color: #181f2a;
        font-family: $font-family-sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
      }

      .rdw-editor-wrapper {
        width: 100%;
        margin-top: 8px;
        margin-bottom: 8px;

        .rdw-editor-toolbar {
          border: 1px solid #c2c7cf;
          border-radius: 4px;
        }

        .rdw-editor-main {
          border: 1px solid #c2c7cf;
          border-radius: 4px;
          height: 250px;
          font-family: $font-family-sans-serif;

          .DraftEditor-root {
            height: auto;
          }
        }
      }

      .info {
        color: #49454f;
        font-family: $font-family-sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }

      .section-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .title {
          color: #181f2a;
          font-family: $font-family-sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
        }

        .btn-delete {
          svg {
            width: 20px;
            height: 20px;
            margin-right: 4px;
          }
        }
      }

      .conclusion {
        padding: 16px 0px;
        color: #293448;
        font-family: $font-family-sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }

      .section-button-action {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 16px;
        padding: 0px;
      }
    }

    .section-question-costume {
      margin-top: 16px;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .title {
        color: #181f2a;
        font-family: $font-family-sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
      }

      .rdw-editor-wrapper {
        width: 100%;
        margin-top: 8px;
        margin-bottom: 8px;

        .rdw-editor-toolbar {
          border: 1px solid #c2c7cf;
          border-radius: 4px;
        }

        .rdw-editor-main {
          border: 1px solid #c2c7cf;
          border-radius: 4px;
          height: 250px;
          font-family: $font-family-sans-serif;
          padding-right: 16px;

          .DraftEditor-root {
            height: auto;
          }
        }
      }

      .info {
        color: #49454f;
        font-family: $font-family-sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }

      .section-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .title {
          color: #181f2a;
          font-family: $font-family-sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
        }

        .btn-delete {
          svg {
            width: 20px;
            height: 20px;
            margin-right: 4px;
          }
        }
      }

      .conclusion {
        padding: 16px 0px;
        color: #293448;
        font-family: $font-family-sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }

      .section-button-action {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 16px;
        padding: 0px;
      }
    }

    .section-button-action {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 16px;
      padding: 16px 0px;

      .btn-edit-product-recommend {
        max-width: 100px;
        width: 100px;
      }
    }

    .section-button-action-add-question {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 16px;
      padding: 16px 0px;
    }

    .question-list {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .card-knowledge-question-product {
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #c2c7cf;
        background: #fff;

        .title-question {
          color: #181f2a;
          font-family: $font-family-sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          margin-bottom: 10px;
        }

        .question {
          color: #293448;
          font-family: $font-family-sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          /* 21px */
        }

        .rdw-editor-wrapper {
          width: 100%;

          .rdw-editor-toolbar {
            border: 1px solid #c2c7cf;
            border-radius: 4px;
          }

          .rdw-editor-main {
            border: 1px solid #c2c7cf;
            border-radius: 4px;
            height: 250px;
            font-family: $font-family-sans-serif;

            .DraftEditor-root {
              height: auto;
            }
          }
        }

        .card-knowledge-question-product-action {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          margin-top: 16px;
          padding-top: 16px;
          border-top: 1px solid #c2c7cf;
          gap: 10px;

          .btn-icon-action {
            display: flex;
            flex-direction: row;
            gap: 6px;
            padding: 8px;
            cursor: pointer;

            &.btn-edit {
              color: $primary;
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
              /* 21px */
            }

            &.btn-delete {
              color: #da2626;
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
              /* 21px */
            }

            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
  }
}

.back-button {
  width: 24px;
  max-width: 24px;
  cursor: pointer;
}

.spacer {
  margin-bottom: 16px;
}

.modal-cancel-edit {
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  max-width: 380px;
  width: 100%;
  gap: 16px;
  justify-content: center;

  .title {
    color: #181f2a;
    font-family: $font-family-sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }

  .subtitle {
    color: #293448;
    font-family: $font-family-sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .spinner {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0px 30px;
  }
}

.modal-add-question-answer {
  max-width: 680px;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;

  .section-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h5 {
      color: #181f2a;
      font-family: $font-family-sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;

      svg {
        width: 28px;
        height: 28px;
        display: none;
      }
    }
  }

  .section-body {
    .title {
      color: #181f2a;
      font-family: $font-family-sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
    }

    .rdw-editor-wrapper {
      width: 100%;
      margin-top: 8px;
      margin-bottom: 8px;

      .rdw-editor-toolbar {
        border: 1px solid #c2c7cf;
        border-radius: 4px;
      }

      .rdw-editor-main {
        border: 1px solid #c2c7cf;
        border-radius: 4px;
        height: 200px;
        font-family: $font-family-sans-serif;

        .DraftEditor-root {
          height: auto;
        }
      }
    }
  }
}

.question-details-product-content {
  .register-store-summary {
    margin-top: 60px;

    .register-store-summary-content {
      width: 100%;
      margin: 0px auto;
      background-color: #fff;
      padding: 80px;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .register-store-summary-main-content {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .title {
          color: #181f2a;
          font-family: $font-family-sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .subtitle {
          color: #7b8598;
          text-align: center;
          font-family: $font-family-sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .btn-empty {
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .section-detail-question-list {
    margin: 16px 0px;

    .question-list {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .card-knowledge-question-product {
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #c2c7cf;
        background: #fff;

        .title-question {
          color: #181f2a;
          font-family: $font-family-sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          margin-bottom: 10px;
        }

        .question {
          color: #293448;
          font-family: $font-family-sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          /* 21px */
        }

        .rdw-editor-wrapper {
          width: 100%;

          .rdw-editor-toolbar {
            border: 1px solid #c2c7cf;
            border-radius: 4px;
          }

          .rdw-editor-main {
            border: 1px solid #c2c7cf;
            border-radius: 4px;
            height: 250px;
            font-family: $font-family-sans-serif;

            .DraftEditor-root {
              height: auto;
            }
          }
        }

        .card-knowledge-question-product-action {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          margin-top: 16px;
          padding-top: 16px;
          border-top: 1px solid #c2c7cf;
          gap: 10px;

          .btn-icon-action {
            display: flex;
            flex-direction: row;
            gap: 6px;
            padding: 8px;
            cursor: pointer;

            &.btn-edit {
              color: $primary;
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
              /* 21px */
            }

            &.btn-delete {
              color: #da2626;
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
              /* 21px */
            }

            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .page-ecommerce {
    .page-ecommerce-content {
      .register-store-summary {
        .register-store-summary-content {
          padding: 60px;

          .register-store-summary-main-content {
            .title {
              text-align: center;
            }
          }
        }
      }

      .integrated-store-summary {
        .store-summary-information {
          .store-summary-information-content {
            display: flex;
            overflow-x: scroll;
            overflow-y: hidden;

            .card-store-information {
              flex-basis: auto;

              .product-sync {
                gap: 20px;
              }
            }
          }

          .store-summary-product-content {
            .header-product-store-section {
              .header-product-store {
                gap: 10px;

                .input-search-product-store {
                  min-width: 280px;
                }

                .dropdown-checkbox-costum {
                  min-width: 80px;
                  display: none;
                }

                .dropdown-checkbox-mobile {
                  display: block;
                  min-width: auto;
                  border-radius: 4px;
                  border: 1px solid #a4abb8;
                  padding: 8px 16px;
                  cursor: pointer;

                  .btn-filter {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                      width: 24px;
                      height: 24px;
                    }
                  }
                }
              }
            }

            .header-product-store-filter {
              flex-direction: column !important;
              align-items: flex-start !important;

              .reset-filter {
                font-size: 12px !important;
              }

              .spacer {
                display: none !important;
              }

              .filter-active {
                overflow-x: scroll !important;
                overflow-y: hidden !important;
                width: 100% !important;

                .btn-filter {
                  min-width: max-content !important;
                }
              }
            }

            .store-summary-product {
              grid-template-columns: repeat(2, minmax(0, 1fr));
            }
          }
        }
      }
    }
  }

  .question-details-product-content {
    .register-store-summary {
      .register-store-summary-content {
        padding: 60px;

        .register-store-summary-main-content {
          .title {
            text-align: center;
          }
        }
      }
    }
  }

  .modal-filter-bottom {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 40;
    font-family: $primary;

    .filter-bottom {
      border-top: 1px solid #a4abb8;
      width: 100%;
      padding: 20px 30px;
      position: absolute;
      bottom: 0px;
      background-color: #fff;
      border-radius: 25px 25px 0px 0px;

      .section-filter-bottom {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .section-header {
          display: flex;
          flex-direction: row;
          gap: 20px;
          justify-content: space-between;
          align-items: center;

          span {
            font-size: 18px;
            font-weight: 600;
          }

          svg {
            width: 30px;
            height: 30px;
          }
        }

        .list-filter {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .checkbox-label {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0px;
            color: #293448;
            font-family: $font-family-sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            gap: 8px;

            .checkbox-input {
              border: 1px solid #a4abb8;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }

  .page-content-knowledge {
    .service-knowledge-product-store {
      margin-top: 12px;
      padding: 16px 12px;

      .input-title {
        text-align: center;
      }

      .input-search-service-knowledge {
        min-width: 100%;
      }
    }

    .service-knowledge-list-product-store {
      .service-knowledge-list {
        grid-template-columns: repeat(1, minmax(0, 1fr));

        .card-service-knowledge {
          .card-content {
            .title-service-knowledge {
              max-width: 270px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .page-ecommerce-details {
    max-width: 100%;

    .btn-add-service-knowledge-mobile {
      display: block;
      margin-bottom: 30px;
      width: 100%;

      svg {
        width: 20px;
      }
    }

    .header-section-costume {
      flex-direction: column;
      gap: 12px;

      .page-header-content {
        .section-edit-title {
          .input-edit-title {
            width: 100%;
          }
        }
      }

      .page-header-action {
        .btn-add-service-knowledge {
          display: none;
        }

        .card-action {
          display: none;
        }
      }

      .page-header-action-mobile {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        gap: 12px;

        .btn-icon-action {
          display: flex;
          flex-direction: row;
          gap: 6px;
          padding: 8px 10px;
          flex: 1;
          justify-content: center;
          border-radius: 8px;

          &.btn-edit {
            color: $primary;
            font-family: $font-family-sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            /* 21px */
          }

          &.btn-delete {
            color: #da2626;
            font-family: $font-family-sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            /* 21px */
          }

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }

  .modal-add-question-answer {
    margin: 0px;
    height: 100vh;

    .modal-content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-y: auto;
      border-radius: 0px;
      padding-bottom: 40px;

      .section-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .modal-title {
          font-size: 18px;
          font-weight: 600;
          display: contents;

          svg {
            width: 28px;
            height: 28px;
            display: block;
          }
        }
      }

      .section-body {
        padding: 20px;
        flex: none;
      }

      .modal-footer {
        display: flex;
        flex-direction: column-reverse;
        gap: 10px;

        .btn {
          width: 100%;
          padding: 14px 0px;
        }
      }
    }
  }
}
