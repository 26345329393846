.tabs {
  .tabs-nav {
    display: block;

    .tabs-nav-content {
      display: flex;
      border-bottom: 1px solid #a4abb8;

      .btn-tabs {
        font-family: $font-family-sans-serif;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        text-align: center;
        padding: 8px 16px;
        font-size: 16px;
        font-weight: 400;
        background-color: transparent;
        border: none;
        font-style: normal;

        &.btn-tabs-active {
          font-weight: 700;
          color: $primary;
          border-bottom: 3px solid #1cac93;
          margin-bottom: -2px;
        }

        &.btn-tabs-error {
          font-weight: 700;
          color: $primary;
          border-bottom: 3px solid #d84d4d;
          margin-bottom: -2px;
        }

        &.full {
          flex: 1;
          justify-content: center;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .tabs {
    .tabs-nav {
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;

      .tabs-nav-content {
        display: flex;
        border-bottom: none;

        .btn-tabs {
          width: max-content;
          justify-content: center;

          &.btn-tabs-active {
            border-bottom: 4px solid #1cac93;
          }

          &.btn-tabs-error {
            border-bottom: 4px solid #d84d4d;
          }

          &.full {
            flex: 1;
            justify-content: center;
          }
        }
      }
    }
  }
}
