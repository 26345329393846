//
// authentication.scss
//

// authentication home icon
.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
}

// auth 2

.auth-logo {
  .auth-logo-dark {
    display: $display-block;
    background: #141414;
    color: #fff;
    text-align: center;

    h5 {
      color: #fff;
    }
  }

  .auth-logo {
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
  }

  .auth-logo-light {
    display: $display-none;
  }

  img {
    height: 60px;
  }
}

.auth-body-bg {
  background-color: $card-bg;
}

// auth-pass-inputgroup

.auth-pass-inputgroup {
  input[type="input"] + .btn .mdi-eye-outline {
    &:before {
      content: "\F06D1";
    }
  }
}

// authentication full page

.auth-full-bg {
  background-color: rgba($primary, 0.25);
  display: flex;

  @media (min-width: 1200px) {
    height: 100vh;
  }

  &::before {
    content: "";
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
  }

  .bg-overlay {
    background: url("../../../images/bg-auth-overlay.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.auth-full-page-content {
  display: flex;

  @media (min-width: 1200px) {
    min-height: 100vh;
  }
}

.auth-review-carousel {
  &.owl-theme {
    .owl-dots {
      .owl-dot {
        span {
          background-color: rgba($primary, 0.25);
        }
        &.active,
        &:hover {
          span {
            background-color: $primary;
          }
        }
      }
    }
  }
}

.plan-service {
  .card-body {
    border: 1px solid #eee;
    cursor: pointer;
  }

  .active {
    border: 1px solid $primary;
  }
}

.auth-page {
  height: 100vh;

  .section-auth-full {
    display: flex;
    min-height: 100%;

    .section-image {
      display: block;
      flex: 1;

      .section-image-banner {
        background-color: #182a4e;
        padding: 50px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 100%;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .section-content {
      background-color: #fff;
      padding: 50px 30px;
      flex: 1;
      position: relative;

      &.bg-mobile-verify {
        @media (max-width: 768px) {
          background-color: #183277 !important;
        }
      }

      .mobile-logo {
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translate(-50%, 0%);
        display: none;
        background-color: #fff;
        width: 100%;
        padding: 20px 16px;
        cursor: pointer;

        @media (max-width: 768px) {
          display: block;
        }
      }

      .content {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 100%;

        .content-auth {
          padding: 3rem;

          .title-text {
            font-family: $font-family-sans-serif;
            font-size: 24px;
            font-weight: 700;
            color: #181f2a;

            @media (max-width: 768px) {
              font-size: 20px;
            }
          }

          .subtitle-text {
            font-family: $font-family-sans-serif;
            font-size: 16px;
            font-weight: 400;
            color: #464a53;

            @media (max-width: 768px) {
              font-size: 14px;
            }
          }

          @media (max-width: 768px) {
            padding: 0px 6px;
          }

          .sub-text-auth {
            width: 400px;

            @media (max-width: 768px) {
              width: 100%;
            }
          }

          .error-msg {
            width: 400px;

            @media (max-width: 768px) {
              width: 100%;
            }
          }

          .form-auth {
            width: 420px;
            margin: auto;

            .section-button {
              .btn.disabled {
                background-color: #c2c7cf !important;
                border: 1px solid #c2c7cf !important;
              }
            }

            @media (max-width: 768px) {
              width: 100%;
            }

            .invalid-feedback {
              width: 400px;

              @media (max-width: 768px) {
                width: 100%;
              }
            }
          }
        }

        .content-verification {
          display: flex;
          flex-direction: column;
          background: #fff;
          box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.1);
          padding: 42px;
          width: 500px;

          .title-text {
            font-family: $font-family-sans-serif;
            font-size: 24px;
            font-weight: 700;
            color: #181f2a;

            @media (max-width: 768px) {
              font-size: 20px;
            }
          }

          .subtitle-text {
            font-family: $font-family-sans-serif;
            font-size: 16px;
            font-weight: 400;
            color: #464a53;

            @media (max-width: 768px) {
              font-size: 14px;
            }
          }

          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
  }
}
