.notif {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  max-width: 320px;
  width: 420px;
  border-radius: 8px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  background: #ffffff;

  .notif-body {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .notif-body-content {
      display: flex;
      align-items: flex-start;

      .body-logo {
        flex-shrink: 0;
        padding-top: 2px;

        .logo-notif {
          width: 40px;
          height: 40px;
          border-radius: 100%;
        }
      }

      .body-content {
        flex: 1;
        margin-left: 12px;

        .title {
          font-size: 14px;
          font-weight: 600;
          color: #111827;
          margin: 0px;
        }

        .body {
          font-size: 12;
          color: #6b7280;
          margin: 0px;
        }
      }
    }
  }

  .notif-close {
    display: flex;
    border-left: 1.5px;
    border-left-color: #1cac93;
    border-left-style: solid;
    overflow: hidden;
    border-radius: 0px 8px 8px 0px;

    .btn-notif {
      width: 100%;
      border-radius: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 400;
      padding: 16px;
      border: none;
      color: #1cac93;
      background-color: #ffffff;
    }
  }
}