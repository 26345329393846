.integration-web {
  .nav-link {
    font-size: 18px;
  }

  .tab-content {
    padding-top: 20px;

    .content-container {
      padding: 10px 0px;
      flex-direction: column;
      display: flex;
      gap: 20px;

      textarea {
        min-height: 120px;
      }

      .input-configuration-list {
        display: flex;
        align-items: center;

        .input-configuration-field {
          display: flex;
          align-content: space-around;
          justify-content: flex-start;
          align-items: flex-end;
          width: 100%;

          input.field-input {
            &.form-control {
              width: 50%;
            }

            &.name {
              width: 100%;
            }
          }

          .form-check {
            margin-left: 10px;
            width: 160px;
            height: 35px;

            .form-label {
              margin-top: 5px;
            }

            .form-check-input {
              float: right;
            }
          }
        }
      }

      .text-editor-count {
        display: flex;
        align-content: flex-end;
        align-items: center;
        margin-top: 5px;
      }

      .field-form {
        .title {
          color: #181f2a;
          font-family: $font-family-sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          width: 100%;
          display: block;

          a.title-link {
            text-decoration: underline !important;
          }
        }

        .subtitle {
          color: #616d84;
          font-family: $font-family-sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          width: 100%;
          margin-top: 6px;
          display: block;
        }

        .field-input {
          margin-top: 8px;

          .text-input {
            display: block;
            width: 100%;
            padding: 0.47rem 0.75rem;
            font-size: 0.8125rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            appearance: none;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
          }

          &.error {
            border: 1px solid #d84d4d;
          }
        }

        .field-color {
          display: flex;
          flex-direction: row;

          .color {
            width: 100px;
            height: 40px;
            padding: 0px;
          }
        }
      }
    }

    .btn-custom {
      width: 100%;
      display: flex;
      margin-top: 20px;

      button {
        margin-left: auto;
      }
    }
  }

  .code-highlight {
    position: relative;

    button#code-highligt-web-copy {
      border: none;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 11px;
      color: #fff;
      position: absolute;
      top: 0;
      background: none;
      cursor: pointer;
      right: 0;
    }

    .instruction {
      margin-top: 15px;
    }
  }

  .upload-logo {
    .field-form {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      margin-bottom: 6px;

      .field-input {
        flex: 1;
      }

      .button-upload {
        background-color: $primary;
        color: #fff;
        border: none;
        padding: 0 10px;
        cursor: pointer;
        margin-top: 8px;
        margin-left: -6px;
        border-radius: 0px 0.25rem 0.25rem 0px;
      }
    }

    .thumbnail {
      border: 1px dotted #e6e6e6;
      text-align: center;
      padding: 3px;
      position: relative;

      .close-icon {
        position: absolute;
        top: 0;
        width: 20px;
        height: 20px;
        cursor: pointer;

        svg {
          fill: red;
        }
      }

      img {
        max-height: 50px;
        max-height: 50px;
      }
    }
  }

  .mock-live-chat {
    &.form-container {
      .card {
        max-height: 100%;
      }

      .card-body {
        background-color: #eafaf7;
        display: block;
      }

      .welcome-text {
        font-size: 14px;
        text-align: center;
        padding: 0 15px;
      }

      .login-form-container {
        border-radius: 5px;
        display: block;
        max-width: 100%;
        padding: 20px;

        form {
          display: block;
          margin: 0 auto;
          width: 100%;
        }

        .form-group {
          flex-direction: column;
          margin-bottom: 15px;
          margin-top: 15px;
          position: relative;
          width: 100%;
        }

        input,
        textarea {
          border: 1px solid #ccc;
          border-radius: 4px;
          box-sizing: border-box;
          padding: 10px;
          width: 100%;
        }

        label {
          display: block;
          font-size: 14px;
          margin-bottom: 5px;
          text-align: left;
          font-weight: 400;
        }

        .required {
          color: #d84d4d;
        }

        .action {
          display: block;
          margin-top: 20px;

          button {
            background-color: #29ccb1;
            border: none;
            border-radius: 4px;
            color: #fff;
            cursor: pointer;
            display: block;
            padding: 10px;
            width: 100%;
          }
        }
      }
    }
  }
}
