.mock-live-chat {

    .container {
        width: 350px;
        height: 565px;
        right: 10px;
        bottom: 10px;
        border: 0;
        border-radius: 10px;
        pointer-events: auto;
        box-shadow: rgba(0, 0, 0, 0.2) -2px 7px 10px 0px;
        padding: 0;
        background: #fff;

        .card {
            overflow: scroll;
            max-height: 550px;
            padding: 0;
            height: 100%;
        }

        .card-header {
            background-color: #fff;
            padding: 15px;
            font-weight: 600;
            text-align: left;
            display: flex;
            justify-content: flex-start;
            flex-wrap: nowrap;
            align-items: flex-end;
            position: relative;
            border-bottom: 1px solid #f6f6f6;

            img {
                width: 30px;
                height: 30px;
                margin-right: 15px;
                color: #666;
            }
        }

        .card-body {
            position: relative;
            display: flex;
            flex-direction: row;
            height: 100%;
            overflow: auto;
            box-sizing: border-box;
            color: rgba(0, 0, 0, .87);
            background-color: #fff;
            font-size: 1rem;

            .message-container {
                order: 1;
                z-index: 1;
                flex-grow: 1;
                flex-basis: 65%;
                border-right: solid 0px #d1dbe3;

                .message-list-wrapper {
                    box-sizing: border-box;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    overflow: hidden;
                    padding: 0 1.2em 0 0.8em;
                }

                .message-list {
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    min-height: 1.25em;
                    position: relative;
                    color: rgba(0, 0, 0, .87);
                    background-color: #fff;

                    .cs-message-list .cs-message.cs-message--incoming,
                    .cs-message-list .cs-message-group.cs-message-group--incoming {
                        margin-right: auto;
                    }

                    .cs-message-list__scroll-wrapper>.cs-message,
                    .cs-message-list__scroll-wrapper>.cs-message-group {
                        max-width: 85%;
                    }

                    section.cs-message-group {
                        margin-bottom: 20px;
                    }

                    .cs-message-group--incoming {
                        justify-content: flex-start;
                    }

                    .cs-message-group {
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: row;
                        margin: 0.4em 0 0 0;
                    }

                    .cs-message-group--incoming .cs-message-group__messages .cs-message .cs-message__content {
                        border-radius: 0 0.7em 0.7em 0;
                        color: rgba(0, 0, 0, .87);
                        background-color: #eafaf7 !important;
                    }

                    .cs-message__content {
                        box-sizing: border-box;
                        color: rgba(0, 0, 0, .87);
                        background-color: #c6e3fa;
                        margin-top: 0;
                        padding: 0.6em 0.9em;
                        border-radius: 0.7em 0.7em 0.7em 0.7em;
                        white-space: pre-wrap;
                        overflow-wrap: anywhere;
                        word-break: break-word;
                        font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
                        font-weight: normal;
                        font-size: .91em;
                        font-feature-settings: normal;
                        font-variant: normal;
                    }

                    .cs-message-group--outgoing .cs-message-group__messages .cs-message .cs-message__content {
                        border-radius: 0.7em 0 0 0.7em;
                        color: rgba(0, 0, 0, .87);
                        background-color: #6ea9d7;
                    }

                    .cs-message__html-content {
                        line-height: 1.5em;
                    }

                    .message-bubble-container .cs-message:after {
                        clear: both;
                    }

                    .message-bubble-container .cs-message:before,
                    .message-bubble-container .cs-message:after {
                        content: ".";
                        display: block;
                        height: 0;
                        overflow: hidden;
                    }

                    .cs-message-group--outgoing .cs-message-group__messages .cs-message:only-child .cs-message__content {
                        border-radius: 0.7em 0.7em 0 0.7em;
                    }

                    .cs-message-group--outgoing .cs-message-group__messages .cs-message:last-child .cs-message__content {
                        border-radius: 0.7em 0 0.7em 0.7em;
                    }

                    .cs-message-group--outgoing .cs-message-group__messages .cs-message:first-child .cs-message__content {
                        border-radius: 0.7em 0 0 0.7em;
                    }

                    .cs-message-group--outgoing .cs-message-group__messages .cs-message .cs-message__content {
                        border-radius: 0.7em 0 0 0.7em;
                        color: rgba(0, 0, 0, .87);
                    }

                    .cs-message-group--outgoing {
                        justify-content: flex-end;
                        margin-left: auto;
                    }

                    .message-bubble-container .cs-message:after {
                        clear: both;
                    }

                    .message-bubble-container .cs-message:before,
                    .message-bubble-container .cs-message:after {
                        content: ".";
                        display: block;
                        height: 0;
                        overflow: hidden;
                    }
                }

                .message-input {
                    box-sizing: border-box;
                    flex-grow: 1;
                    font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
                    outline: 0;
                    border-top: 0 none;
                    border-right: 0 none;
                    border-bottom: 0 none;
                    border-left: 0 none;
                    overflow: visible;
                    color: rgba(0, 0, 0, .87);
                    overflow-wrap: anywhere;
                    word-break: break-word;
                    position: absolute;
                    width: 90%;
                    bottom: 0;

                    .content-editor-wrapper {
                        box-sizing: border-box;
                        position: relative;
                        margin: 0;
                        padding: 0.6em 0.9em;
                        border-radius: 0.7em;
                        flex-grow: 1;
                        color: #000;
                        background-color: #fff;
                        border: 1px solid #e6e6e6;

                        .tools {
                            display: flex;
                            flex-direction: row-reverse;
                            box-sizing: border-box;

                        }

                        .cs-button {
                            align-self: flex-end;
                            font-size: 1.2em;
                            margin-top: 0;
                            margin-bottom: 0;
                            align-self: center;
                            color: #6ea9d7;
                            background-color: transparent;
                            box-sizing: border-box;
                            display: inline-block;
                            vertical-align: middle;
                            text-align: center;
                            color: #6ea9d7;
                            background-color: transparent;
                            border: none;
                            border-radius: 0.7em;
                            margin: 0.1em 0.2em;
                            padding: 0.2em 0.7em;
                            outline: none;
                            font-size: 1em;
                            line-height: 1.5;

                            :disabled {
                                opacity: .38;
                            }

                            svg {
                                overflow: visible;
                                box-sizing: content-box;
                                display: var(--fa-display, inline-block);
                                height: 1em;
                                overflow: visible;
                                vertical-align: -0.125em;
                            }
                        }
                    }
                }
            }
        }
    }
}