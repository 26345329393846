.test-chat-scenario {
    height: 100vh;

    .navbar {
        padding: 10px 20px;
        background: white;

        .navbar-brand {
            p {
                display: inline-block;
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 0;
            }

            .btn {
                border: none;
                margin-right: 5px;
            }
        }
    }

    .chat-scenario {
        background-color: #e6f2f0;

        .live-chat {
            width: 100% !important;
            max-width: 920px;
            margin: 0 auto;
            padding: 0;
            border-radius: 0;
            display: block;
            height: 92vh;
            box-shadow: none;
            border: none;

            .cs-main-container {
                border: none;
            }

        }
    }
}