.section-profile {
  padding: 42px 36px;
  background-color: #fff;
  border-radius: 4px;

  .section-profile-content {
    .section-card-profile {
      h5 {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 16px;
      }

      .card {
        border-radius: 8px;
        border: 1px solid #d9d9d9;
        .section-column-profile {
          .package-name {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            color: $black;
          }

          .section-package {
            margin-top: 16px;
            display: flex;
            flex-direction: row;
            gap: 8px;

            .content-description {
              display: flex;
              flex-direction: column;
              gap: 4px;
              flex: 1;

              .title-content {
                color: #777;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 4px;
                margin-bottom: 8px;
              }

              .feature-content {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $black;

                i {
                  margin-right: 4px;
                  color: $black;
                }
              }
            }

            .content-price {
              flex: 1;
              display: flex;
              flex-direction: column;

              .price {
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: $black;
              }

              .suffix {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $black;
                margin-top: 8px;
              }
            }
          }

          .button {
            width: 100%;
            background-color: transparent;
            color: $black;
            text-align: center;
            border: none;
            margin-top: 40px;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 16px;

            &.text-gray {
              color: rgba(204, 204, 204, 0.8);
            }
          }
        }

        button {
          margin-left: 8px;
        }
      }
    }

    .section-button {
      display: flex;
      flex-direction: row;
      justify-content: end;
    }
  }
}

.modal-cancel-billing {
  padding: 32px;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: $black;
    margin-bottom: 8px;
  }

  .subtitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #404040;
  }

  .text-reason-modal-cancel {
    margin: 20px 0px 10px;

    .text-area-reason {
      resize: none;
      height: 100px !important;
    }
  }

  .section-button {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-top: 16px;

    .secondary {
      width: 80px;
      text-align: center;
      cursor: pointer;
    }
  }
}

.small-input-password {
  height: 36px !important;
}
