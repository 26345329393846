.dashboard {
  margin-bottom: 60px;

  .title-page {
    color: #181f2a;
    font-family: $font-family-sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 16px;
  }

  .subtitle-page {
    color: #181f2a;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 12px;
  }

  .section-dashboard {
    margin: 20px 0px;

    .section-dashboard-content {
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      gap: 12px;

      .section-billing {
        grid-column: span 7 / span 7;
        min-height: 155px;
      }

      .section-chat {
        grid-column: span 5 / span 5;
        min-height: 155px;
      }

      .information-card {
        font-family: $font-family-sans-serif;
        font-size: 11px;
        flex: 1;
        padding: 10px;
        margin-bottom: 0px !important;

        .card-title {
          display: flex;
          flex-direction: row;
          line-height: normal;
          gap: 8px;
          align-items: center;
          font-family: "Roboto";

          span {
            letter-spacing: 1px;
            font-size: 13px;
            font-weight: 500;
          }
        }

        svg {
          width: 18px;
          height: 18px;
        }

        .information-card-content {
          display: grid;
          grid-template-columns: repeat(12, minmax(0, 1fr));
          gap: 12px;
          margin-top: 12px;

          .d-flex {
            &.billing {
              grid-column: span 3 / span 3;
            }

            &.conversation {
              grid-column: span 4 / span 4;
            }

            .text-muted {
              font-size: 12px;
              margin-bottom: 4px;
            }

            .highlight {
              font-size: 24px;
              color: $primary;
              font-weight: 700;
            }

            .next-cycle-date {
              font-size: 16px;
              margin-top: 4px;

              a {
                font-size: 10px;
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .conversation-information {
    .button-view {
      background-color: #1cac93;
      color: #fff;
      padding: 7px 10px;
      border-radius: 5px;
      margin-top: 0px;
      display: inline-block;
    }
  }

  .conversation-stats {
    font-family: $font-family-sans-serif;

    .card-body {
      padding: 24px;

      .card-title {
        font-size: 20px;
        color: #181f2a;

        h4 {
          font-weight: 700;
        }

        p {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .scenario-list {
    font-family: $font-family-sans-serif;

    .card-body {
      padding: 24px;

      .card-title {
        font-size: 20px;
        color: #181f2a;
        h4 {
          font-weight: 700;
        }

        p {
          font-size: 14px;
          font-weight: 400;
        }
      }

      .scenario-item {
        padding-bottom: 10px;
        align-items: center;
        display: flex;
        gap: 20px;

        img {
          width: 40px;
          height: 40px;
        }

        .information {
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;
          flex-direction: row;
          width: 100%;
          padding-top: 3px;

          .title {
            font-size: 18px;

            p {
              margin-bottom: 0;
            }

            small {
              font-size: 12px;
              color: rgb(174, 174, 174);
            }
          }
        }

        .button-view {
          background-color: $primary;
          font-size: 12px;
          padding: 12px 15px;
          color: white;
          float: right;
          border-radius: 5px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .dashboard {
    .section-dashboard {
      .section-dashboard-content {
        .section-billing {
          grid-column: span 12 / span 12;
        }

        .section-chat {
          grid-column: span 12 / span 12;
        }

        .information-card {
          padding: 0px;

          .information-card-content {
            margin-top: 20px;

            .d-flex {
              &.billing {
                grid-column: span 4 / span 4;
              }

              &.conversation {
                grid-column: span 4 / span 4;
              }

              .text-muted {
                height: 35px;
                display: flex;
                align-items: center;
              }

              .highlight {
                font-size: 16px;
                margin-bottom: 0px;
              }

              .next-cycle-date {
                font-size: 16px;

                a {
                  margin-top: 6px;
                  font-size: 10px;
                  display: block;
                }
              }
            }
          }
        }
      }
    }

    .scenario-list {
      .card-body {
        .scenario-item {
          gap: 24px;

          img {
            width: 53px;
            height: 53px;
          }

          .information {
            display: flex;
            justify-content: space-between;
            gap: 12px;
            flex-direction: column;
            width: 100%;

            .title {
              font-size: 18px;

              p {
                margin-bottom: 0;
                color: #181f2a;
                font-weight: 500;
                text-transform: capitalize;
              }

              small {
                font-size: 12px;
                color: #949494;
                max-width: 147px;
                width: 147px;
                display: block;
              }
            }
          }

          .button-view {
            background-color: $primary;
            font-size: 14px;
            padding: 6px;
            color: white;
            width: 100%;
            border-radius: 5px;
            text-align: center;
          }
        }
      }
    }
  }
}
