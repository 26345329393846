.section-billing-notification {
  border-radius: 4px;
  border: 1px solid #ffecb5;
  background: #fff3cd;
  padding: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #664d03;
    margin-bottom: 8px;
  }

  .subtitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  a {
    text-decoration: underline !important;
    font-weight: "700";
    color: "#664d03";
  }
}

.section-billing {
  font-family: $font-family-sans-serif;

  .section-package {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 24px;
    border-radius: 4px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.02);
    height: 100%;
    flex-direction: row;

    .section-package-now {
      flex: 1;

      .title-package {
        color: #79747e;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-size: 14px;
      }

      .package {
        display: flex;
        flex-direction: column;
        margin-top: 12px;

        .name {
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          color: $black;
        }
      }

      .section-package-button {
        display: flex;
        flex-direction: row;
        gap: 4px;
        margin-top: 12px;

        .upgrade {
          color: $primary;
          font-weight: 500;
          font-style: normal;
          font-size: 12px;
          cursor: pointer;
        }

        .info {
          color: #ccc;
          font-style: normal;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }

    .section-quota {
      flex: 1;

      .title-package {
        color: #79747e;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-size: 14px;
      }

      .section-quota-remaining {
        display: flex;
        flex-direction: row;
        margin-top: 12px;
        gap: 10px;
        justify-content: flex-start;
        flex-wrap: wrap;

        .quota {
          h6 {
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            color: $black;
          }
        }
      }
    }

    .section-bill {
      display: flex;
      flex-direction: column;

      .title-package {
        color: #79747e;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-size: 14px;
      }

      .bill {
        color: $primary;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-size: 24px;
        margin-top: 12px;
      }

      .info-bill {
        color: $black;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-size: 12px;
        margin-top: 8px;
      }
    }
  }

  .section-tabel {
    margin-top: 30px;
    background-color: #ffffff;
    padding: 24px;
    border-radius: 4px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.02);

    table,
    td,
    th {
      border: 1px solid #b9b9b9;
    }

    table {
      border-collapse: collapse;
      border: 1px solid #b9b9b9;

      thead {
        background-color: #f0f0f0;

        tr {
          th {
            font-weight: 600;
            color: $black;
          }
        }
      }

      tbody {
        tr {
          td {
            background-color: #ffffff;
          }
        }
      }
    }

    .tabel-no-data {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 20px;
      width: 100%;
      border-width: 0px 1px 1px 1px;
      border-color: #ccc;
      border-style: solid;
      margin-top: -20px;
    }
  }
}

.section-modal-billing {
  max-width: 600px;
  width: 100%;
  border-radius: 16px;

  .section-content-modal-billing {
    display: flex;
    flex-direction: column;
    padding: 32px;

    .mobile-header {
      display: none;
    }

    .title {
      color: $black;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .sub-title {
      margin-top: 10px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .section-package-menu {
      margin-top: 16px;
      background-color: #e6f2f0;
      padding: 16px 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 8px;
      gap: 16px;

      .button-active {
        display: flex;
        height: 43px;
        padding: 12px 20px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: #24b39b;
        color: #fff;
        cursor: pointer;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .button {
        display: flex;
        height: 43px;
        padding: 12px 20px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: #e6f2f0;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        cursor: pointer;
      }

      .section-package-info {
        display: flex;
        flex: 1;
        height: 43px;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        i {
          font-size: 15px;
          color: #24b39b;
        }

        span {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .section-package-tiers {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 16px;

      .tiers {
        border-radius: 8px;
        border: 1px solid #ccc;
        display: flex;
        padding: 16px;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        cursor: pointer;

        .checkbox {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          padding: 2px;
          border: 1px solid #ccc;
        }

        .tiers-content {
          display: flex;
          flex-direction: column;
          gap: 8px;
          flex: 1;

          .tiers-content-onboarding {
            display: flex;
            flex-direction: row;
            gap: 12px;

            .title {
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            .section-package-info {
              display: flex;
              flex: 1;
              justify-content: flex-start;
              align-items: center;
              gap: 6px;

              i {
                font-size: 15px;
                color: #24b39b;
              }
            }
          }

          .title {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          .content {
            display: flex;
            flex-direction: row;
            gap: 30px;

            .content-description {
              display: flex;
              flex-direction: column;
              gap: 4px;
              flex: 1;

              .title-content {
                color: #777;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 4px;
                margin-bottom: 8px;
              }

              .feature-content {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $black;

                i {
                  margin-right: 4px;
                  color: $black;
                }
              }
            }

            .content-price {
              display: flex;
              flex-direction: column;
              gap: 4px;
              flex: 1;

              .price-discount {
                position: relative;

                span {
                  color: #777;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                }

                .line {
                  position: absolute;
                  width: 50%;
                  top: 50%;
                  right: 50%;
                  left: 0;
                  border-bottom: 1px solid #777;
                }
              }

              .price {
                color: $black;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }

              .price-suffix {
                color: $black;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .price-free {
                text-align: center;
                margin: auto;
                color: $black;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
            }
          }
        }
      }
    }

    .loading {
      margin: 15px 0;
      text-align: center;
    }

    .section-button {
      margin-top: 16px;
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;

      .button {
        display: flex;
        height: 43px;
        padding: 12px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        cursor: pointer;
      }

      span {
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        cursor: pointer;
        margin-left: 12px;
      }
    }

    .section-note {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $black;
      margin: 0px;

      span {
        color: #24b39b;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .section-package-confirmation {
      display: flex;
      flex-direction: row;
      gap: 8px;
      margin-top: 16px;

      .tiers {
        border-radius: 8px;
        border: 2px solid #ccc;
        display: flex;
        padding: 16px;
        flex-direction: column;
        gap: 8px;
        flex: 1;

        &.update {
          border: 2px solid #24b39b;
        }

        .error-text {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 6px;
          color: #ff5555;
        }

        .badge-title {
          padding: 8px 16px;
          border-radius: 4px;
          background-color: #ccc;
          color: #777777;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 16px;
          width: max-content;

          &.update {
            background-color: #24b39b;
            color: #ffffff;
          }
        }

        .tiers-content {
          display: flex;
          flex-direction: column;
          gap: 8px;
          flex: 1;

          .title {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          .content {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .content-description {
              display: flex;
              flex-direction: column;
              gap: 4px;
              flex: 1;

              .feature-content {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $black;

                i {
                  margin-right: 4px;
                  color: $black;
                }
              }
            }

            .content-price {
              display: flex;
              flex-direction: column;
              gap: 4px;
              flex: 1;

              .price-discount {
                position: relative;

                span {
                  color: #777;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                }

                .line {
                  position: absolute;
                  width: 50%;
                  top: 50%;
                  right: 50%;
                  left: 0;
                  border-bottom: 1px solid #777;
                }
              }

              .price {
                color: $black;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }

              .price-suffix {
                color: $black;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }

      .section-addtional-quota {
        border-radius: 8px;
        border: 2px solid #ccc;
        display: flex;
        padding: 16px;
        flex-direction: column;
        gap: 8px;
        flex: 1;

        .title {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .content-description {
          display: flex;
          flex-direction: column;
          gap: 4px;
          flex: 1;
          margin-top: 16px;
          margin-bottom: 16px;

          .feature-content {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: $black;

            i {
              margin-right: 4px;
              color: $black;
            }
          }
        }

        .content-price {
          display: flex;
          flex-direction: column;
          gap: 4px;
          flex: 1;

          .price {
            color: $black;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }
    }

    .section-list {
      padding-left: 16px;
      margin-top: 20px;
    }
  }
}

@media (max-width: 768px) {
  .section-billing {
    .row {
      gap: 24px;
      .section-package {
        flex-direction: column;
        gap: 24px;
      }
    }
  }

  .section-modal-billing {
    font-family: $font-family-sans-serif;
    max-width: 100%;
    width: 100%;
    margin: 0px;
    height: 100vh;

    .modal-content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-y: auto;
      border-radius: 0px;
      padding-bottom: 40px;

      .section-content-modal-billing {
        padding: 0px 12px;

        .mobile-header {
          display: block;
          padding: 16px;
          margin: 0px -16px 24px;
          border-bottom: 1px solid #c2c7cf;

          .content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            .icon-close {
              width: 28px;
              height: 28px;
            }

            .title {
              font-size: 18px;
              font-weight: 600;
            }
          }
        }

        .section-package-menu {
          .section-package-info {
            span {
              font-size: 14px;
            }
          }
        }

        .section-package-tiers {
          .tiers {
            .tiers-content {
              .content {
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }
}
