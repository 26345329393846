.custom-scenario {
  &.page-content {
    padding: calc(#{$header-height}) 0 $footer-height 0;
  }

  .container-fluid {
    padding: 0;
  }
}

.modal-custom-scenario {
  text-align: center;

  .modal-title {
    margin: 0 auto;
  }

  .modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
    background-color: var(--bs-modal-footer-bg);
    border-top: var(--bs-modal-footer-border-width) solid
      var(--bs-modal-footer-border-color);
    border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius);
    align-content: space-around;
  }
}

.only-mobile {
  display: none;
}

.navbar-costume-scenario {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #ffffff;
  padding: 6px 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1;
  transition: width 0.3s ease;

  &.large-navbar {
    left: 200px;
  }

  &.small-navbar {
    left: 70px;
  }

  .date {
    color: #666;
    font-style: italic;
  }

  .message-setting {
    cursor: pointer;

    svg {
      width: 25px;
      height: 25px;

      &:hover {
        fill: #24b39b;
      }
    }
  }

  .title {
    color: #080e1a;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  .navbar-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 6px;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      &.icon-warning {
        color: #fde047;
      }

      &.icon-warning-danger {
        color: #e11a1a !important;
        background: transparent;
        border: none;
      }

      &.icon-check {
        color: #22c55e;
      }

      &.icon-check-danger {
        color: #e11a1a;
      }

      i {
        min-width: 1.75rem;
        font-size: 1.25rem;
      }
    }
  }

  .badge.draft {
    font-size: 16px;
    position: relative;
    cursor: pointer;
  }
}

.vertical-menu-scenario {
  z-index: 10;
  background: #151515;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  padding-top: $header-height;
  box-shadow: $box-shadow;
  transition: width 0.3s ease;

  &.large-sidebar {
    width: $sidebar-width;

    .navbar-brand-box {
      position: fixed;
      top: 0px;
      z-index: 99;

      &.large-sidebar {
        width: $sidebar-width;
        transition: width 0.3s ease;
      }

      @media (max-width: 991.98px) {
        display: none;
      }
    }
  }

  &.small-sidebar {
    width: 70px;

    .navbar-brand-box {
      position: fixed;
      top: 0px;
      z-index: 99;

      &.small-sidebar {
        width: 70px;
        transition: width 0.3s ease;
      }

      @media (max-width: 991.98px) {
        display: none;
      }
    }
  }

  @media (max-width: 991.98px) {
    top: $header-height;
    padding-top: 0px;
  }

  #sidebar-menu {
    padding: 10px 0 30px 0;

    .ps-active {
      > .has-arrow {
        &:after {
          transform: rotate(-180deg);
        }
      }
    }

    .has-arrow {
      &:after {
        content: "\F0140";
        font-family: "Material Design Icons";
        display: block;
        float: right;
        transition: transform 0.2s;
        font-size: 1rem;
      }
    }

    ul {
      li {
        a {
          display: block;
          padding: 0.625rem 1.5rem;
          color: $sidebar-menu-item-color;
          position: relative;
          font-size: 13px;
          transition: all 0.4s;

          i {
            // display: inline-block;
            min-width: 1.75rem;
            padding-bottom: 0.125em;
            font-size: 1.25rem;
            line-height: 1.40625rem;
            vertical-align: middle;
            color: $sidebar-menu-item-icon-color;
            transition: all 0.4s;
          }

          &:hover {
            color: $sidebar-menu-item-hover-color;

            i {
              color: $sidebar-menu-item-hover-color;
            }
          }
        }

        .badge {
          margin-top: 4px;
        }

        ul.sub-menu {
          padding: 0;

          li {
            a {
              padding: 0.4rem 1.5rem 0.4rem 3.5rem;
              font-size: 13px;
              color: $sidebar-menu-sub-item-color;

              &:hover {
                color: $sidebar-menu-item-hover-color;
              }
            }

            ul.sub-menu {
              padding: 0;

              li {
                a {
                  padding: 0.4rem 1.5rem 0.4rem 4.5rem;
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }

    .menu-new {
      width: 200px;
      background-color: #151515;

      .menu-list {
        display: flex;
        flex-direction: column;
        list-style-type: "none";
        padding: 0;
        margin: 0;

        li {
          .menu {
            a {
              display: flex;
              padding: 1rem 1.5rem;
              color: #a6b0cf;
              position: relative;
              font-size: 13px;
              font-weight: 600;
              flex-direction: row;
              align-items: center;
              transition: width 0.3s ease;

              &:hover {
                background-color: #ffffff;
                color: #ffffff;
              }

              .icon-sidebar {
                width: 22px;
                height: 22px;
              }

              i {
                // display: inline-block;
                min-width: 1.75rem;
                font-size: 1.25rem;
                color: $sidebar-menu-item-icon-color;
                padding-bottom: 0px;
                line-height: 0px;
              }

              &:hover {
                color: $sidebar-menu-item-hover-color;

                i {
                  color: $sidebar-menu-item-hover-color;
                }
              }

              .rotate-open {
                transform: rotate(90deg);
                // transition: transform 0.3s ease-in-out;
              }
            }
          }

          /* CSS */
          .submenu {
            background-color: #2b2b2b;

            a {
              display: flex;
              padding: 1rem 1.5rem;
              color: #a6b0cf;
              position: relative;
              font-size: 13px;
              transition: all 0.4s;
              font-weight: 600;
              flex-direction: row;
              align-items: center;

              &:hover {
                background-color: #ffffff;
                color: #ffffff;
              }

              .icon-sidebar {
                transition: all 0.4s;
                width: 22px;
                height: 22px;
              }

              i {
                // display: inline-block;
                min-width: 1.75rem;
                font-size: 1.25rem;
                color: $sidebar-menu-item-icon-color;
                transition: all 0.4s;
              }

              &:hover {
                color: $sidebar-menu-item-hover-color;

                i {
                  color: $sidebar-menu-item-hover-color;
                }
              }

              .rotate-open {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }
  }
}

.react-flow {
  $color_1: #222;
  $color_2: #3e4449;
  $background-color_1: #778899;
  $background-color_2: #fff;
  background-color: #17294d;
  font-size: 16px;

  .starting-point {
    max-width: 200px;
    min-height: 80px;

    .custom-node__body {
      border-radius: 10px !important;
      font-size: 16px;
      font-weight: 400;
      background-color: #fff;
      padding: 15px;
      font-family: "Roboto", "Times New Roman", Times, serif;
      text-align: left;

      strong {
        font-size: 16px;
      }

      small {
        font-size: 12px;
      }
    }
  }

  .react-flow__node.selected {
    .starting-point {
      .custom-node__body {
        border-color: #24b39b;
        border-width: 4px;
        border-style: solid;
      }
    }

    .custom-node {
      position: relative;
      width: 320px;
      text-align: center;
      border-radius: 15px;
      box-shadow: 4px 5px #000;
      border-color: #24b39b;

      &.node-error {
        border: 1px solid rgb(194, 0, 0);
      }

      &.finding-context {
        height: 72px;
        width: 150px;
        text-align: center;
        font-weight: 700;
      }

      .fa {
        margin-right: 5px;
      }

      .custom-node__body {
        position: relative;
        z-index: 99;
        border-radius: 0 0 10px 10px;
      }

      .custom-node__body.starting-body {
        background-color: #fff;
        padding: 15px;
      }

      &.target-connectable {
        .react-flow__handle {
          background: #24b39b;
          cursor: crosshair;

          &::after {
            content: "+";
          }
        }
      }

      .handle-wrapper {
        &.react-flow__handle {
          width: 50px;
          height: 50px;
          padding: 10px;
          border-style: dotted;
          background-color: #78b1a7 !important;
          opacity: 0.5;
          z-index: 99;
          cursor: inherit;
        }

        &.react-flow__handle-bottom {
          bottom: -10px;
        }

        &.react-flow__handle-top {
          top: -10px;
        }

        &.react-flow__handle-left {
          left: -10px;
        }

        &.react-flow__handle-right {
          right: -10px;
        }
      }

      .react-flow__handle.custom-handle {
        // width: 100%;
        // height: 100%;
        // background: blue;
        // position: absolute;
        // top: 0;
        // left: 0;
        // border-radius: 0;
        // transform: none;
        // border: none;
        // opacity: 0;
        // padding: 15px;

        &.source {
          // &.react-flow__handle-right {
          //   position: absolute;
          //   right: 10px;
          // }

          // &.react-flow__handle-top {
          //   position: absolute;
          //   top: -10px
          // }

          // &.react-flow__handle-bottom {
          //   position: absolute;
          //   bottom: -10px
          // }

          // &.react-flow__handle-left {
          //   position: absolute;
          //   left: -10px
          // }
        }
      }

      .react-flow__handle.target {
        width: 100%;
        height: 100%;
        border-radius: 0;
        padding: 54px;
        left: 0;
        opacity: 0;
      }

      .delete {
        position: absolute;
        top: 0;
        left: -38px;
        z-index: 999;

        button {
          background-color: #fff;
          border-color: #e6e6e6;
          border-radius: 50%;
          padding: 5px 4px 5px 5px;
          // width: 20px;
        }
      }

      .send-message {
        .text-input {
          margin-bottom: 10px;
        }
      }
    }

    input.resize {
      width: 400px;
      height: 100px;
    }
  }

  .action-list {
    position: relative;
    margin-left: 20px;

    .card {
      border-radius: 20px;
      width: 300px;

      .card-body {
        padding: 0;
        border-radius: 20px;
      }

      .list-group-item {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .chevron {
      position: absolute;
      left: 0;
      top: 50%;
      display: block;
      /*Height should be double border thickness*/
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }

    .list-group-item {
      padding-bottom: 0;
      cursor: pointer;
    }

    .list-group-item:hover {
      background-color: #d3d3d3;
    }

    .item-body {
      position: relative;
      display: flex;
      align-items: flex-start;

      strong {
        font-size: 14px;
      }

      .description {
        p {
          font-size: 12px;
        }
      }

      img {
        width: 33px;
        margin-right: 11px;
        height: 33px;
      }
    }

    .chevron::after {
      position: absolute;
      display: block;
      content: "";
      border: 15px solid transparent;
      /*Adjust chevron size*/
    }

    .chevron::after {
      left: -10px;
      /*Adjust thickness*/
      border-top-color: #fff;
      /*Match chevron background colour*/
    }
  }

  .custom-node {
    position: relative;
    width: 320px;
    text-align: center;
    border-radius: 15px;
    box-shadow: 4px 5px #000;

    &.finding-context {
      height: 72px;
      width: 150px;
      text-align: center;
      font-weight: 700;
    }

    &.node-error {
      border: 1px solid rgb(194, 0, 0);
    }

    .fa {
      margin-right: 5px;
    }

    .react-flow__handle .fa {
      color: #fff;
      font-size: 9px;
    }

    .custom-node__body {
      position: relative;
      z-index: 99;
      border-radius: 0 0 10px 10px;
    }

    .handle-wrapper {
      &.react-flow__handle {
        width: 50px;
        height: 50px;
        padding: 10px;
        border-style: dotted;
        background-color: #34c38f !important;
        opacity: 0.5;
        z-index: 99;
      }

      &.react-flow__handle-bottom {
        bottom: -10px;
      }

      &.react-flow__handle-top {
        top: -10px;
      }

      &.react-flow__handle-left {
        left: -10px;
      }

      &.react-flow__handle-right {
        right: -10px;
      }
    }

    .react-flow__handle.custom-handle {
      // width: 100%;
      // height: 100%;
      // background: blue;
      // position: absolute;
      // top: 0;
      // left: 0;
      // border-radius: 0;
      // transform: none;
      // border: none;
      // opacity: 0;
      // padding: 15px;

      &.source {
        // &.react-flow__handle-right {
        //   position: absolute;
        //   right: 10px;
        // }

        // &.react-flow__handle-top {
        //   position: absolute;
        //   top: -10px
        // }

        // &.react-flow__handle-bottom {
        //   position: absolute;
        //   bottom: -10px
        // }

        // &.react-flow__handle-left {
        //   position: absolute;
        //   left: -10px
        // }
      }
    }

    .react-flow__handle.target {
      width: 100%;
      height: 100%;
      border-radius: 0;
      padding: 54px;
      left: 0;
      opacity: 0;
    }

    .delete {
      position: absolute;
      top: 0;
      left: -38px;
      z-index: 999;

      button {
        background-color: #fff;
        border-color: #e6e6e6;
        border-radius: 50%;
        padding: 5px 4px 5px 5px;
        // width: 20px;
      }
    }

    .send-message {
      .text-input {
        margin-bottom: 10px;
      }
    }
  }

  .react-flow__edge.selected .react-flow__edge-path {
    stroke: #2a9e74 !important;
  }

  .custom-node__body {
    background-color: #e9e9e9;
    font-size: 11px;
    padding: 10px 10px 5px;

    .rwd-editor-main {
      cursor: text;
    }

    .form-control {
      font-size: 11px;
    }

    .text-wrapper {
      background-color: #fff;
      padding: 10px 3px;
      margin-bottom: 10px;
      font-size: 14px;
      position: relative;

      img.three-dot-icon {
        width: 14px !important;
        display: inline;
        vertical-align: revert;
        position: absolute;
        right: 10px;
      }

      .image {
        max-width: 150px;

        img {
          width: 100%;
        }
      }

      &.closed {
        background-color: rgb(210, 205, 205);
        transition: 0.1s ease-out;

        .react-flow__handle {
          background-color: rgb(193, 0, 0) !important;

          &::after {
            content: "-" !important;
          }
        }
      }

      .text-input {
        display: flex;
        justify-content: space-between;
        align-items: center !important;
        padding: 0 10px;
        cursor: default;
        text-align: left;
      }

      i.fa.fa-th {
        font-size: 20px;
        color: #d3d3d3;
        cursor: pointer;
        position: absolute;
        right: 0;
      }
    }
  }

  .custom-node__header {
    font-size: 12px;

    background-color: #fff;
    border-radius: 10px 10px 0 0;
    font-weight: 700;
    text-align: left;
    display: flex;
    justify-content: space-between;

    .title {
      display: block;
      width: 100%;

      .loop-active {
        display: inline;

        span.badge {
          background: #93bfff;
          color: #4b4b4b;
          margin: 0 10px;
          font-size: 10px;
        }
      }
    }

    .title,
    .actions {
      padding: 8px 10px;
    }

    .actions {
      position: relative;

      .card {
        position: absolute;
        bottom: -45px;
        width: 150px;
        z-index: 999;
        font-weight: 400;
        font-size: 10px;
        background: transparent;
        left: 50px;

        .card-body {
          position: relative;
          padding: 0;
          margin: 0;

          .chevron {
            position: absolute;
            left: 0;
            top: 50%;
            display: block;
            /*Height should be double border thickness*/
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            transform: rotate(90deg);

            &::after,
            &::before {
              position: absolute;
              display: block;
              content: "";
              border: 15px solid transparent;
              /*Adjust chevron size*/
            }

            &::before {
              border-top-color: #dddddd;
              left: -15px;
            }

            &::after {
              left: -15px;
              top: -1px;
              /*Adjust thickness*/
              border-top-color: #fff;
              /*Match chevron background colour*/
            }
          }
        }

        .list-group {
          position: relative;
          border-radius: 5px;

          .list-group-item {
            text-align: left;
            display: flex;
            justify-content: space-between;

            p {
              width: 100%;
              margin: 0;
              padding: 0 5px;
            }

            &:hover {
              color: #24b39b;

              .fa {
                color: #fff;
              }
            }
          }

          .action-delete,
          .action-delete .fa {
            color: rgb(187, 11, 11);
          }
        }

        i.fa {
          color: #e6e6e6;
          border: #e6e6e6;
        }

        &.hide {
          opacity: 0;
          display: none;
        }

        &.show {
          opacity: 1;
          display: block;
          -o-transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          transition: 0.3s ease-in-out;
        }
      }
    }

    .fa,
    svg,
    img {
      margin-right: 5px;
      font-size: 18px;
      width: 25px;
    }
  }

  .react-flow__node-custom {
    font-size: 10px;
    width: 180px;
    background: #f5f5f6;
    color: $color_1;
    // box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
    border-radius: 0;

    .react-flow__handle {
      width: 20px;
      height: 20px;
      z-index: 99;
      background-color: #78b1a7;
      text-align: center;
      cursor: inherit;

      .fa {
        color: #fff;
        font-size: 9px;
      }

      &::after {
        content: ">";
        color: #fff;
        font-size: 15px;
        padding: 0;
        position: relative;
        top: -2px;
      }
    }

    .fa.fa-question-circle {
      font-size: 10px;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    li.list-group-item {
      font-size: 12px;
      padding: 5px;
      cursor: pointer;
      color: $color_2;

      i {
        margin-right: 5px;
      }

      &:hover {
        background-color: $background-color_1;
      }
    }
  }

  .react-flow__handle {
    width: 20px;
    height: 20px;
    z-index: 99;
    background-color: #24b39b;
    text-align: center;

    &::after {
      content: "+";
      color: #fff;
      font-size: 16px;
      padding: 0;
      position: relative;
      top: -3px;
      font-family: monospace;
    }

    &.unconnectable {
      background-color: #9f9f9f;

      &::after {
        content: "=";
      }
    }
  }
}

.scenario-config {
  .scenario-config-icon {
    z-index: 999;
    background: transparent;
    border: none;
  }

  .node-configuration {
    .sidebar {
      position: fixed;
      top: 67px;
      display: none;
      width: 368px;

      &.sidebar--open {
        margin-top: 0;
        display: block;
      }

      .card {
        padding: 10px 15px;
      }

      .title {
        font-weight: 400;
      }

      .subtitle {
        font-size: 12px;
        padding: 5px;
        color: #464a53;
      }
    }
  }
}

.node-configuration {
  font-family: "Roboto", Georgia, "Times New Roman", Times, serif;
  position: relative;
  width: 100%;

  .sidebar {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    background-color: #e6eaf2;
    transition: width 0.3s ease;
    border-right: 1px #4d606e solid;
    z-index: 99;
    width: 360px;
    display: none;

    &.sidebar--open {
      display: block;
      height: calc(100vh - 67px);
      margin-top: 67px;
      transition: width 0.3s ease;
      padding: 0;
      opacity: 1;
      overflow: scroll;

      .header {
        font-family: "Roboto", serif;
        background-color: #fff;
        height: 51px;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        display: flex;
        text-align: left;
        align-items: center;
        justify-content: space-between;
        padding: 10px 12px;

        .icon-triple-dot {
          display: block;
        }

        .section-action-config-btn {
          position: relative;
          padding: 6px 0px;
          display: flex;
          flex-direction: row;
          align-items: center;

          .action-config {
            position: absolute;
            top: 45px;
            right: 0px;
            z-index: 999;
            width: 175px;

            .section-action-config {
              display: flex;
              flex-direction: column;
              width: 100%;
              background: #fff;
              box-shadow: $box-shadow;
              border: 1px solid #cac6c6;
              border-radius: 8px;
              padding: 12px 0px;

              .btn-costume {
                display: flex;
                flex-direction: row;
                cursor: pointer;
                justify-content: flex-start;
                align-items: center;
                padding: 6px 0px 6px 10px;
                width: 100%;

                svg {
                  width: 16px;
                  height: 16px;

                  &.copy {
                    color: #000;
                  }
                }

                p {
                  font-size: 16px;
                  margin: 0px;
                  font-family: $font-family-sans-serif;
                  font-weight: 400;

                  &.delete {
                    color: #e84a5f;
                  }
                }
              }
            }
          }

          .icon-close-mobile {
            margin: 0px 4px;
            display: none;
          }
        }

        .btn {
          border: none;
        }
      }
    }

    .sidebar-wrapper {
      position: relative;
      height: 100%;

      .input-error {
        color: rgb(206, 2, 2);
        margin: 5px 15px 10px;
      }

      .btn-outline-primary {
        background-color: #fff;
        margin-right: 10px;

        &:hover {
          color: #555;
        }
      }

      .footer-save {
        position: relative;
        bottom: 0;
        right: 0;
        height: 175px;
        width: 100%;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        margin-top: 35px;

        p {
          font-size: 15px;
          font-weight: 600;
          padding-top: 5px;
        }
      }
    }

    .card-body {
      padding: 15px 5px;
    }

    .small-note {
      margin: 10px 0;
      padding: 3px;
      font-size: 8px;
      color: grey;
      text-align: left;
    }

    .rdw-editor-wrapper {
      box-sizing: content-box;
      position: relative;
      margin-bottom: 30px;
    }

    .rdw-emoji-wrapper {
      margin-bottom: 0;
    }

    .rdw-option-wrapper {
      border: none;
    }

    .rdw-editor-main {
      padding-left: 5px;
      height: 100%;
      min-height: 100px;
      border-color: transparent;
    }

    .rdw-editor-toolbar.toolbarClassName {
      position: absolute;
      bottom: -37px;
      width: 100%;
      border-color: transparent;
      padding: 6px 5px 0;
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
    }

    .toolbar-variable {
      font-size: 12px;
      border: none;
      padding: 3px 5px;
      color: #666;

      h6.dropdown-header {
        cursor: pointer;
      }

      .variable-item {
        &:hover {
          background-color: #e6e6e6;
        }
      }
    }

    .rdw-inline-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      flex-wrap: wrap;

      .rdw-option-wrapper {
        border: none;
      }
    }

    .send-message-config {
      margin: 10px;

      .image {
        max-height: 200px;
        overflow-y: scroll;

        img {
          width: 100%;
        }
      }

      .ask-message-item {
        &:hover {
          .handlers {
            opacity: 1;
          }
        }

        .handlers {
          opacity: 0;
          top: 0;
          position: absolute;
          right: 5px;
          -o-transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          transition: 0.3s ease-in-out;
          cursor: pointer;
          z-index: 99;
        }

        ul {
          list-style: none;
          display: flex;
          margin-bottom: 0;

          li {
            margin-right: 3px;
            cursor: pointer;
          }
        }

        svg {
          padding: 3px;
          width: 25px;
          height: 25px;
          color: #fff;
          background: #24b39b;
          border-radius: 3px;
        }
      }

      .col {
        margin-top: 15px;

        .btn {
          width: 100%;
          background-color: #fff;

          &:hover {
            color: #3f5159;
          }
        }
      }
    }

    .processor-config {
      .configuration {
        padding: 5px;
        margin-top: 25px;

        .title {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }

  .card {
    margin: 8px 15px;

    &.validation,
    &.variable {
      padding: 5px;

      .title {
        font-family: "Roboto";
        font-size: 14px;
        font-weight: 400;
        color: #080e1a;
      }

      .input-group {
        .form-control {
          padding-left: 0;
        }
      }

      .subtitle {
        color: #464a53;
        font-size: 12px;
      }
    }

    &.variable {
      span.input-group-text {
        background-color: transparent;
        border-right: none;
        border-right-width: 0px !important;
      }

      input.form-control {
        border-left: none;
      }
    }
  }

  .sidebar .sidebar-position {
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 12px;
    text-align: left;
  }

  .sidebar .sidebar-position:hover {
    background-color: #3f5159;
    border-right: 5px #e84a5f solid;
  }

  .sidebar svg {
    color: #e84a5f;
    min-width: 25px;
  }

  .icon-triple-dot {
    display: none;
  }

  .sidebar--open .sidebar-position span {
    display: block;
  }
}

.node-config-popover {
  .list-group-item {
    border: none;
    position: relative;
    border-radius: 5px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-size: 14px;
    padding: 5px;

    &.action-delete,
    &.action-delete .fa {
      color: rgb(187, 11, 11);
    }

    svg {
      width: 14px;
    }

    p {
      width: 100%;
      margin: 0;
      padding: 0 5px;
    }

    &:hover {
      color: #24b39b;

      .fa {
        color: #fff;
      }
    }
  }
}

.scenario-modal-confirmation {
  text-align: center;

  .modal-header {
    border: none;
  }

  .modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .only-mobile {
    display: block;
  }

  .navbar-costume-scenario {
    &.large-navbar {
      left: 0px;
    }

    &.small-navbar {
      left: 0px;
      top: 56px;
      padding: 12px 12px 6px;
    }
  }

  .vertical-menu-scenario {
    display: none;
  }

  .node-configuration {
    .sidebar {
      &.sidebar--open {
        margin-top: 0px;
        height: 100vh;
        width: 100%;
        z-index: 1002;

        .header {
          .section-action-config-btn {
            .icon-close-mobile {
              margin: 0px 4px;
              display: block;

              .icon {
                color: #131313;
              }
            }
          }
        }
      }
    }
  }
}
