.referral {
  .referral-code-container {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .referral-code-title {
      font-size: 24px;
      color: #333;
      font-weight: 400;
      margin-bottom: 20px;
      text-align: center;
    }

    .referral-code-content {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .referral-code-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px dotted #6d6d6d;

      &.referral-code-input {
        align-items: center;
        flex-direction: column;
        border-bottom: 0px;
      }

      span svg {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    .label {
      font-weight: bold;
      color: #555;
    }

    .value {
      color: #007bff;
      font-family: monospace;
      font-size: 14px;

      &.bold{
        font-weight: 800;
      }
    }
  }


}