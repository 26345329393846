.page-integration {
  margin-bottom: 60px;
  font-family: "Poppins", sans-serif;

  .title-page {
    color: #181f2a;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 16px;
  }

  .subtitle-page {
    color: #293448;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .page-integration-content {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 16px;

    .page-integration-menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      cursor: pointer;
      background-color: #fff;
      padding: 16px 24px;
      border-radius: 4px;
      box-shadow: 0px 6px 6px 0px rgba(123, 138, 145, 0.1);

      .page-integration-menu-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;

        img {
          width: 48px;
          height: 48px;
        }

        .page-integration-status {
          display: flex;
          flex-direction: column;
          gap: 2px;

          .title {
            color: #181f2a;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
          }

          .title::first-letter {
            text-transform: capitalize;
          }

          .status {
            color: #616d84;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
          }

          .error {
            color: #da2626;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
          }
        }

        .title {
          color: #181f2a;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
        }

        .title::first-letter {
          text-transform: capitalize;
        }

        .date {
          color: #949494;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .page-integration-action {
        display: flex;
        flex-direction: row;
        align-items: center;

        .action {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 16px;

          .action-menu {
            position: relative;

            svg {
              width: 24px;
              height: 24px;

              &.icon-menu {
                width: 26px;
                height: 26px;
              }
            }

            .action-modal {
              position: absolute;
              width: 170px;
              top: 35px;
              right: 0px;
              background: #fff;
              z-index: 10;
              display: flex;
              flex-direction: column;
              border-radius: 4px;
              box-shadow: 0px 6px 6px 0px rgba(123, 138, 145, 0.25);

              .btn-icon-action {
                display: flex;
                flex-direction: row;
                gap: 6px;
                padding: 12px 14px;

                &.btn-edit {
                  color: #293448;
                  font-family: $font-family-sans-serif;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 150%;
                  /* 21px */
                }

                &.btn-delete {
                  color: #da2626;
                  font-family: $font-family-sans-serif;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 150%;
                  /* 21px */
                }

                svg {
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
        }

        .action-chevron {
          cursor: pointer;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  .overlay-sidebar-right {
    background: rgba(51, 51, 51, 0.5);
    position: absolute;
    top: 70px;
    right: 0;
    left: 0;
    bottom: 0;

    .sidebar-right-content {
      background: #e6eaf2;
      height: 100%;
      width: 368px;
      float: right;

      .section-header {
        padding: 16px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        background-color: #ffffff;

        span {
          color: #080e1a;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        svg {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }

      .section-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 16px;

        .description {
          color: #293448;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }

        .summary-toggle {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .summary {
            display: flex;
            flex-direction: column;
            gap: 4px;
            width: 80%;

            .title {
              color: #000000;
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            .description {
              color: #293448;
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

.page-integration-shop {
  margin-bottom: 60px;
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  width: 1024px;
  max-width: 1024px;
  margin: auto;

  .page-integration-shop-details {
    .header-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;

      .title {
        color: #181f2a;
        font-family: $font-family-sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        text-transform: capitalize;
      }
    }

    .page-integration-shop-content {
      margin-top: 24px;

      .page-integration-shop-stepper {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        flex-direction: row;
        align-items: center;
        position: relative;

        .progress-content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          z-index: 1;

          .progress-bar {
            height: 3px;
            position: relative;
            background: #c2c7cf;
            overflow: hidden;
            flex: 1;

            span {
              display: block;
              height: 100%;
            }

            .progress {
              background-color: $primary;
              animation: progressBar 3s ease-in-out;
              animation-fill-mode: both;
            }

            @keyframes progressBar {
              0% {
                width: 0;
              }

              100% {
                width: 100%;
              }
            }
          }
        }

        .step {
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;
          padding: 0px 16px;
          z-index: 2;
          background-color: #ffffff;

          .icon-stepper {
            width: 24px;
            height: 24px;
          }

          .step-content {
            display: flex;
            flex-direction: column;
            gap: 8px;
            justify-content: center;
            width: 142px;
            max-width: 142px;

            .step-icon {
              border-radius: 100%;
              padding: 8px;
              background-color: #ffffff;
              width: 40px;
              height: 40px;
              border: 1px solid #000;
              margin: auto;

              &.active {
                background-color: #eafaf7;
                border: 1px solid #eafaf7;
              }

              &.disabled {
                background-color: #c2c7cf;
                border: 1px solid #c2c7cf;
              }
            }

            .step-description {
              color: #616d84;
              text-align: center;
              font-family: $font-family-sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;

              &.disabled {
                color: #c2c7cf;
                font-weight: 400;
              }
            }

            .step-title {
              color: #293448;
              text-align: center;
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              margin: 0px;

              &.active {
                color: $primary;
                font-weight: 700;
              }

              &.disabled {
                color: #c2c7cf;
                font-weight: 400;
              }
            }
          }
        }
      }

      .page-integration-shop-form {
        .subtitle {
          color: #293448;
          font-family: $font-family-sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          margin-bottom: 8px;
        }

        .page-integration-shop-form-content {
          padding: 16px 0px;

          .title-content-page {
            color: #293448;
            font-family: $font-family-sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            margin-bottom: 16px;
            display: block;
          }

          .form-integration-shop {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .field-form {
              .title {
                color: #181f2a;
                font-family: $font-family-sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;

                a.title-link {
                  text-decoration: underline !important;
                }
              }

              .text-muted {
                font-size: 10px;
                margin-top: 10px;
                display: block;
              }

              .field-input {
                margin-top: 8px;

                &.error {
                  border: 1px solid #d84d4d;
                }
              }
            }

            .error-message {
              margin-top: 4px;
              color: #d84d4d;
            }

            .noted {
              color: #616d84;
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              margin-bottom: 16px;
            }
          }

          .field-btn {
            display: flex;
            justify-content: end;
            flex-direction: row;

            .btn.disabled {
              background-color: #cccccc !important;
              border: 1px solid #cccccc !important;
            }
          }

          .tabs-content-input {
            padding: 16px 0px 0px;

            .tabs-content-input-description {
              color: #293448;
              font-family: $font-family-sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              margin-bottom: 16px;
              display: block;
            }

            .rdw-editor-wrapper {
              .rdw-editor-toolbar {
                border: 1px solid #c2c7cf;
              }

              .rdw-editor-main {
                border: 1px solid #c2c7cf;
                border-radius: 4px;
                height: 206px;
                font-family: $font-family-sans-serif;

                .DraftEditor-root {
                  height: auto;
                }
              }

              &.error {
                color: #000 !important;

                .rdw-editor-toolbar {
                  border: 1px solid #d84d4d;
                }

                .rdw-editor-main {
                  border: 1px solid #d84d4d;
                  border-radius: 4px;
                  height: 206px;
                  font-family: $font-family-sans-serif;

                  .DraftEditor-root {
                    height: auto;
                  }
                }
              }
            }

            .tabs-content-error {
              margin-top: 4px;
              color: #d84d4d;
              display: block;
            }
          }

          .form-integration-shop-noted {
            padding: 16px 0px 0px;
            border-top: 1px solid #c2c7cf;

            .title {
              color: #181f2a;
              font-family: $font-family-sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              margin-bottom: 6px;
              display: block;
            }

            .rdw-editor-wrapper {
              .rdw-editor-toolbar {
                border: 1px solid #c2c7cf;
              }

              .rdw-editor-main {
                border: 1px solid #c2c7cf;
                border-radius: 4px;
                height: 206px;
                font-family: $font-family-sans-serif;

                .DraftEditor-root {
                  height: auto;
                }
              }
            }

            .note {
              color: #616d84;
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              margin: 16px 0px 0px;
              display: block;
            }
          }
        }

        .section-form {
          display: flex;
          flex-direction: column;
          gap: 24px;

          .subtitle {
            color: #293448;
            font-family: $font-family-sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 8px;
          }

          .form-integration-shop {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .field-form {
              .title {
                color: #181f2a;
                font-family: $font-family-sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                display: block;

                .title-small {
                  color: #616d84;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                }

                a.title-link {
                  text-decoration: underline !important;
                }
              }

              .subtitle {
                color: #616d84;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
              }

              .field-input {
                margin-top: 8px;

                &.error {
                  border: 1px solid #d84d4d;
                }
              }

              .field-form-radio {
                display: flex !important;
                flex-direction: column;
                gap: 10px;
                margin: 6px 0px;

                .option-radio {
                  display: flex;
                  flex-direction: row;
                  gap: 4px;
                  align-items: center;
                  margin-bottom: 0px;

                  .form-check-input {
                    width: 14px;
                    height: 14px;
                    margin-top: 0px;

                    &.form-check-input:checked {
                      background-color: #1cac93;
                      border-color: #1cac93;
                    }
                  }

                  span {
                    color: #293448;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    margin-left: 8px;
                  }
                }
              }
            }

            .error-message {
              margin-top: 4px;
              color: #d84d4d;
            }

            .noted {
              color: #616d84;
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              margin-bottom: 16px;
            }
          }
        }
      }
    }

    .page-integration-shop-summary {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .summary-toggle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .summary {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .title {
            color: #000;
            font-family: $font-family-sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          .description {
            color: #293448;
            font-family: $font-family-sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }

      .summary-content {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .title {
          color: #000;
          font-family: $font-family-sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .description {
          color: #293448;
          font-family: $font-family-sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }

  .field-btn {
    display: flex;
    justify-content: end;
    flex-direction: row;

    .btn.disabled {
      background-color: #cccccc !important;
      border: 1px solid #cccccc !important;
    }
  }
}

.modal-integration {
  border-radius: 4px;
  display: flex;
  max-width: 380px;
  gap: 16px;

  &.modal-integration-activation {
    max-width: 580px;
  }

  .modal-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .title {
      color: #181f2a;
      font-family: $font-family-sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
    }

    .subtitle {
      color: #293448;
      font-family: $font-family-sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    button {
      font-family: $font-family-sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      &.cstm-danger {
        background-color: #da2626;
        color: #fff;

        &:hover {
          background-color: #d84d4d;
        }
      }
    }

    .section-btn-copy {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      gap: 8px;

      .input-token {
        resize: none;
        height: 150px;
      }

      .icon-btn {
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .section-btn-activation {
      display: flex;
      flex-direction: row;
      justify-content: end;

      button {
        font-family: $font-family-sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        &.cstm-danger {
          background-color: #da2626;
          color: #fff;

          &:hover {
            background-color: #d84d4d;
          }
        }
      }
    }

    .spinner {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 10px 0px 30px;
    }
  }
}

@media (max-width: 768px) {
  .page-integration {
    .page-integration-content {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  .modal-integration {
    margin: auto;
  }

  .page-integration-shop {
    width: 100%;
    max-width: 100%;
    padding: 16px 8px;
    .page-integration-shop-details {
      .page-integration-shop-content {
        .page-integration-shop-form {
          .content {
            margin-top: 10px;
            padding: 0px;

            .nav {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              overflow-x: scroll;
              overflow-y: hidden;

              .nav-item {
                .nav-link {
                  width: max-content;
                }
              }
            }

            .tab-content {
              .row {
                .col-sm-1 {
                  .mock-live-chat {
                    .container {
                      width: 100%;
                      margin-top: 20px;
                    }
                  }
                }

                .col-12 {
                  .mock-live-chat {
                    .container {
                      width: 100%;
                      margin-top: 20px;
                    }
                  }
                }
              }

              .content-container {
                .input-configuration-list {
                  .input-configuration-field {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: 8px;
                    flex-wrap: wrap;

                    .input-mobile {
                      margin-top: 0px;
                      flex: 1;
                    }

                    .form-check {
                      width: 100%;
                      margin: 0px;
                      padding: 0px;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: space-between;

                      label {
                        margin: 0px;
                      }

                      .form-check-input {
                        height: 20px;
                        width: 40px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
