.status-bar {
  width: auto;
  padding: 10px 10px;
  text-align: center;
  margin: -20px -12px 0px -12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.bg-info {
    background-color: #2b5bd8 !important;
  }

  &.bg-success {
    background-color: #1dc14a !important;
  }

  &.bg-error {
    background-color: #da2626 !important;
  }

  .status-bar-content {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
      color: #fff;
    }

    span {
      font-family: $font-family-sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: white;

      &.with-icon {
        margin-left: 10px;
      }

      a {
        text-decoration: underline !important;
      }
    }
  }

  .close-icon {
    width: 24px;
    height: 24px;
    color: #fff;
    cursor: pointer;
  }
}
