.costume-paggination {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-top: 24px;

  .paggination-show-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-right: auto;

    span {
      color: #7b8598;
      font-family: $font-family-sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }

    .dropdown-costume-selected {
      width: 88px;
      max-width: 88px;

      .dropdown-selected {
        margin-top: 0px;
      }
    }
  }

  .paggination-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-left: auto;

    svg {
      width: 16px;
      height: 16px;
    }

    .btn-paggination {
      background-color: #ffffff;
      padding: 8px 10px;
      color: #293448;
      font-family: $font-family-sans-serif;
      font-size: 16px;
      border: 1px solid #c2c7cf;
      font-style: normal;
      font-weight: 400;
      max-width: 40px;
      width: 40px;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;

      &.btn-paggination-current-active {
        background-color: #eafaf7;
        color: #29ccb1;
        border: 1px solid #29ccb1;
      }

      &.disabled {
        background-color: #ffffff;
        color: #c2c7cf;
        border: 1px solid #c2c7cf;
      }
    }

    // .rounded-left {
    //   border-top-left-radius: 8px;
    //   border-bottom-left-radius: 8px;
    // }

    // .rounded-right {
    //   border-top-right-radius: 8px;
    //   border-bottom-right-radius: 8px;
    //   border-right: 1px solid #a4abb8;
    // }
  }
}

@media (max-width: 768px) {
  .costume-paggination {
    flex-direction: column-reverse;
    gap: 20px;

    .paggination-show-data {
      display: none;
    }

    .paggination-action {
      margin: 0px auto;
    }
  }
}
