//
// coming-soon.scss
//

.page-menu-knowledge {
  height: calc(83vh);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
    rgba(0, 0, 0, 0.23) 0px 3px 6px;
  -moz-box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
    rgba(0, 0, 0, 0.23) 0px 3px 6px;
  overflow: hidden;

  .page-container-knowledge {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;

    .page-sidebar {
      width: 25%;

      .page-sidebar-knowledge {
        background: #fff;
        padding-bottom: 40px;
        overflow-y: auto;
        max-height: 100%;
        border-right: 1px solid #e4e4e6;
        z-index: 1px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
          rgba(0, 0, 0, 0.23) 0px 3px 6px;
        -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
          rgba(0, 0, 0, 0.23) 0px 3px 6px;
        -moz-box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
          rgba(0, 0, 0, 0.23) 0px 3px 6px;

        &::-webkit-scrollbar {
          width: 0.5rem; // Set the width of the scroll bar
        }

        &::-webkit-scrollbar-track {
          background-color: transparent; // Set the background color of the scroll bar track
        }

        &::-webkit-scrollbar-thumb {
          background-color: transparent; // Set the background color of the scroll bar thumb (handle)
        }

        .header-siderbar-knowledge {
          position: sticky;
          top: 0px;
          padding: 30px 12px 12px;
          background-color: #fff;

          .title {
            h2 {
              font-size: 20px;
              font-weight: 600;
              margin-bottom: 6px;
            }
          }

          .btn-create-knowledge {
            padding: 12px 4px;
            text-align: center;
            border: 2px dashed #1cac93;
            cursor: pointer;
            border-radius: 10px;

            span {
              color: #1cac93;
              font-size: 16px;
            }
          }
        }

        .content-sidebar-knowledge {
          padding: 10px 0px;

          .knowledge {
            padding: 10px 12px;
            cursor: pointer;
            color: #495057;
            fill: #495057;

            &:hover {
              background-color: #1cac93;
              color: #ffffff;
              fill: #ffffff;
            }

            svg {
              margin-top: 4px;
              height: 28px;
              width: 28px;
            }

            .knowledge-title {
              padding: 0px 10px;
              display: flex;
              flex-direction: row;
              gap: 4px;
              align-items: flex-start;

              .knowledge-question {
                padding-left: 15px;
                display: flex;
                flex-direction: column;

                span {
                  font-size: 16px;
                  font-weight: 600;
                  margin-bottom: 0px;

                  &.subtitle {
                    font-size: 12px;
                    font-weight: 300;
                    margin-bottom: 0px;
                  }
                }
              }
            }

            h5 {
              font-size: 14px;
              font-weight: 400;
              color: #adadaf;
            }

            .knowledge-label {
              display: flex;
              flex-direction: row;
              gap: 4px;

              .labels {
                border-radius: 8px;
                color: #ffffff;
                padding: 4px 8px;
              }
            }
          }

          .knowledge-disable {
            padding: 10px 0px;

            .knowledge-title {
              padding: 0px 10px;
              display: flex;
              flex-direction: row;
              gap: 4px;
              align-items: flex-start;

              .knowledge-question {
                padding-left: 15px;

                h4 {
                  font-size: 16px;
                  font-weight: 600;
                  margin-bottom: 0px;
                  color: #a3a1a1;
                }

                span {
                  font-size: 12px;
                  font-weight: 300;
                  margin-bottom: 0px;
                  color: #a3a1a1;
                }
              }
            }

            h5 {
              font-size: 14px;
              font-weight: 400;
              color: #adadaf;
            }

            .knowledge-label {
              display: flex;
              flex-direction: row;
              gap: 4px;

              .labels {
                border-radius: 8px;
                color: #ffffff;
                padding: 4px 8px;
              }
            }
          }
        }
      }

      .loading-sidebar {
        position: absolute;
        bottom: 0;
        padding: 10px;
        width: 25%;
        text-align: center;
        background: #f9f9f9;
      }
    }

    .page-content-knowledge {
      width: 75%;
      background-color: #f9f9f9;
      height: 100%;
      overflow-y: auto;
      max-height: 100%;

      .content-knowledge {
        width: 100%;
        height: 100%;
        display: block;

        .parent-create-knowledge {
          flex-direction: column;
          display: flex;
          height: 100%;
          padding: 20px;

          .create-knowledge {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            width: 100%;
            border: 4px dashed #1cac93;
            border-radius: 10px;
            height: 100%;
            cursor: pointer;
          }
        }

        .form-knowledge {
          padding: 10px 20px 20px;

          .form-input-knowlede {
            flex-direction: column;
            display: flex;
            height: 100%;

            .close-form {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              margin-top: 20px;

              i {
                cursor: pointer;
              }
            }

            .field-knowledge {
              display: flex;
              flex-direction: row;
              gap: 10px;
              align-items: center;

              &.items-start {
                align-items: flex-start;
              }

              .text-area {
                height: 300px;
                width: 100%;
                resize: none;
              }

              .input-costume-knowledge {
                font-size: 30px;
                color: #333;
                border: none;
                background-color: #f9f9f9;
                width: 100%;

                // Selector child untuk input di dalam class input-costume-knowledge
                input {
                  width: 100%;
                  outline: none;
                }
              }

              .input-costume-knowledge-second {
                font-size: 24px;
                color: #333;
                border: none;
                background-color: #f9f9f9;
                height: 100%;
                width: 100%;
                resize: none;

                // Selector child untuk input di dalam class input-costume-knowledge
                input {
                  width: 100%;
                  outline: none;
                }
              }
            }
          }

          .container-btn-save {
            margin-left: 40px;
            display: flex;
            flex-direction: row;
            gap: 6px;

            .btn-spinner {
              padding: 12px 12px;
              text-align: center;
              border-radius: 4px;
              width: 160px;
              background-color: #1cac93;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .btn-spinner-del {
              padding: 12px 12px;
              text-align: center;
              border-radius: 4px;
              width: 160px;
              background-color: $danger;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .btn-knowledge {
              padding: 12px 12px;
              text-align: center;
              border-radius: 4px;
              font-size: 14px;
              text-transform: capitalize;
              width: 160px;

              &.active {
                background-color: #1cac93;
                color: #fff;
                border: 1px solid #1cac93;
              }

              &.non-active {
                background-color: #fff;
                color: #b4b3b4;
                border: 1px solid #b4b3b4;
              }
            }

            .btn-knowledge-delete {
              padding: 12px 12px;
              text-align: center;
              border-radius: 4px;
              font-size: 14px;
              text-transform: capitalize;
              background-color: $red;
              color: #fff;
              border: none;
              box-shadow: revert;
            }
          }
        }

        .section-question {
          .example-knowledge-question {
            margin-left: 58px;

            h2 {
              font-size: 20px;
              font-weight: 600;
              margin-bottom: 0px;
            }

            .span {
              font-size: 16px;
              font-weight: 400;
              margin-bottom: 0px;
            }

            .button-hide {
              display: flex;
              flex-direction: row;
              align-items: center;
              border: 1px solid #495057;
              padding: 4px 6px;
              border-radius: 4px;
              cursor: pointer;
            }
          }

          h2 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0px;
          }

          ul {
            list-style-type: disc;
            padding-left: 0;
            margin-top: 16px;
            margin-bottom: 16px;

            li {
              padding: 12px 20px;
              background: #ffffff;
              border-radius: 8px;
              box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
                rgba(0, 0, 0, 0.23) 0px 3px 6px;
              -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
                rgba(0, 0, 0, 0.23) 0px 3px 6px;
              -moz-box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
                rgba(0, 0, 0, 0.23) 0px 3px 6px;
              overflow: hidden;
              margin-bottom: 10px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              .question-answer {
                display: flex;
                flex-direction: column;

                span {
                  &.question {
                    font-size: 16px;
                    font-weight: 600;
                  }

                  &.answer {
                    font-size: 14px;
                    font-weight: 400;
                  }
                }
              }

              .question-answer-icon {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 20px;
                align-items: center;

                i {
                  cursor: pointer;

                  &.edit {
                    color: #1cac93;
                  }

                  &.delete {
                    color: $red;
                  }
                }
              }
            }
          }

          .question-list {
            margin-left: 58px;
            padding-bottom: 50px;
            padding-right: 20px;

            .pagination {
              display: flex;
              gap: 6px;
              flex-direction: row;

              .btn-pagination {
                width: 100%;
                max-width: 80px;
              }
            }

            .skeleton-box {
              display: inline-block;
              padding: 20px 20px;
              position: relative;
              overflow: hidden;
              background-color: #dddbdd;
              border-radius: 8px;
              margin-bottom: 10px;

              &::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transform: translateX(-100%);
                background-image: linear-gradient(
                  90deg,
                  rgba(#fff, 0) 0,
                  rgba(#fff, 0.2) 20%,
                  rgba(#fff, 0.5) 60%,
                  rgba(#fff, 0)
                );
                animation: shimmer 2s infinite;
                content: "";
              }

              @keyframes shimmer {
                100% {
                  transform: translateX(100%);
                }
              }
            }

            .empty-question {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 10px;
              padding: 20px 20px;
              background: #ffffff;
              border-radius: 8px;
              box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
                rgba(0, 0, 0, 0.23) 0px 3px 6px;
              -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
                rgba(0, 0, 0, 0.23) 0px 3px 6px;
              -moz-box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
                rgba(0, 0, 0, 0.23) 0px 3px 6px;
              overflow: hidden;
            }
          }
        }
      }

      .page-loading {
        padding: 10px 20px 20px 60px;
        margin-top: 2rem;
        display: flex;
        flex-direction: column;

        .skeleton-box {
          display: inline-block;
          padding: 20px 20px;
          position: relative;
          overflow: hidden;
          background-color: #dddbdd;
          border-radius: 8px;
          margin-bottom: 10px;

          &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            background-image: linear-gradient(
              90deg,
              rgba(#fff, 0) 0,
              rgba(#fff, 0.2) 20%,
              rgba(#fff, 0.5) 60%,
              rgba(#fff, 0)
            );
            animation: shimmer 2s infinite;
            content: "";
          }

          @keyframes shimmer {
            100% {
              transform: translateX(100%);
            }
          }
        }
      }
    }

    .page-onboard-knowledge {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 20px;
      border: 4px dashed #1cac93;
      border-radius: 10px;
      cursor: pointer;
    }
  }
}
