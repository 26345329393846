.page-settings {
  margin-bottom: 60px;

  .title-page {
    color: #181f2a;
    font-family: $font-family-sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 16px;
  }

  .subtitle-page {
    color: #181f2a;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 12px;
  }



  .page-settings-content {
    padding: 16px 24px;
    background-color: #fff;
    margin-top: 24px;

    .settings-content {
      &.with-space-bottom {
        padding-bottom: 140px;
      }

      .settings-header-content {
        padding-top: 24px;
        padding-bottom: 8px;
        border-bottom: 1px solid #c2c7cf;

        h2 {
          color: #181f2a;
          font-family: $font-family-sans-serif;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 0px;
        }

        span {
          color: #181f2a;
          font-family: $font-family-sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 2px;
        }
      }

      .form-integration-settings {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: 24px 0px 0px;
        position: relative;

        .integration-settings {
          max-width: 50%;
          display: flex;
          flex-direction: column;
          gap: 12px;
        }

        .settings-header-content {
          padding-top: 24px;
          padding-bottom: 8px;
          border-bottom: 1px solid #c2c7cf;

          h2 {
            color: #181f2a;
            font-family: $font-family-sans-serif;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 0px;
          }

          span {
            color: #181f2a;
            font-family: $font-family-sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 2px;
          }
        }

        .field-form {
          .title {
            color: #181f2a;
            font-family: $font-family-sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
          }

          .field-input {
            margin-top: 8px;

            &.error {
              border: 1px solid #d84d4d;
            }
          }

          .field-input-selected {
            display: flex;
            flex-direction: column;

            .field-input {
              margin-top: 8px;
              border-radius: 0.25rem !important;
              color: #495057 !important;
              width: 100%;

              &.error {
                border: 1px solid #d84d4d;
              }
            }

            .error {
              margin-top: 4px;
            }
          }
        }

        .error-message {
          margin-top: 4px;
          color: #d84d4d;
        }

        .noted {
          color: #616d84;
          font-family: $font-family-sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          margin-bottom: 16px;
        }

        .field-btn {
          display: flex;
          flex-direction: row;
          justify-content: end;

          .btn.disabled {
            background-color: #cccccc !important;
            border: 1px solid #cccccc !important;
          }
        }

        .field-btn-profile {
          display: flex;
          flex-direction: row;
          justify-content: end;
          position: absolute;
          right: 0px;
          bottom: -420px;

          .btn.disabled {
            background-color: #cccccc !important;
            border: 1px solid #cccccc !important;
          }
        }
      }

      .settings-package {
        width: 100%;
        padding-bottom: 100px;
        max-height: 380px;
        height: 380px;

        .settings-package-content {
          width: 50%;
          display: flex;
          flex-direction: column;
          gap: 12px;

          .section-package {
            margin-top: 30px;

            .card {
              border-radius: 8px;
              border: 1px solid #d9d9d9;

              .section-column-package {
                .package-name {
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  color: $black;
                }

                .section-package {
                  margin-top: 16px;
                  display: flex;
                  flex-direction: row;
                  gap: 8px;

                  .content-description {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    flex: 1;

                    .title-content {
                      color: #777;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      margin-top: 4px;
                      margin-bottom: 8px;
                    }

                    .feature-content {
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      color: $black;

                      i {
                        margin-right: 4px;
                        color: $black;
                      }
                    }
                  }

                  .content-price {
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    .price {
                      font-size: 30px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: normal;
                      color: $black;
                    }

                    .suffix {
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      color: $black;
                      margin-top: 8px;
                    }
                  }
                }

                .button {
                  width: 100%;
                  background-color: transparent;
                  color: $black;
                  text-align: center;
                  border: none;
                  margin-top: 40px;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  margin-bottom: 16px;

                  &.text-gray {
                    color: rgba(204, 204, 204, 0.8);
                  }
                }
              }

              button {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
  }

  .employee {
    .employee-invite {
      margin-top: 30px;
      margin-bottom: 30px;
      display: block;
      position: relative;
      width: 100%;
      height: 25px;
    }

    button.btn.btn-primary {
      width: 80px;
      height: 40px;
      text-align: center;
      margin: 0 auto;
      float: right;
    }

    table {
      td {
        background-color: #fff;
      }
    }
  }

  .input-copy {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 20px;
    align-items: baseline;
    gap: 10px;

    label {
      min-width: 120px;
    }

    .copy-text {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 100%;
    }

    .copy-button {
      background-color: #007bff;
      color: white;
      border: none;
      padding: 5px 10px;
      border-radius: 4px;
      cursor: pointer;
    }

    .copy-button:hover {
      background-color: #0056b3;
    }
  }
}

@media (max-width: 768px) {
  .page-settings {
    .page-settings-content {
      border-radius: 8px;

      .settings-content {
        .form-integration-settings {
          .integration-settings {
            max-width: 100%;
          }

          .field-btn-profile {
            bottom: -455px;
            width: 100%;
            left: 0px;
            right: 0px;

            .btn {
              width: 100%;
            }
          }

          .field-btn {
            .btn {
              width: 100%;
            }
          }
        }

        .settings-package {
          .settings-package-content {
            width: 100%;
          }
        }
      }
    }
  }
}